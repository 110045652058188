import { useEffect } from 'react';
import firebase from 'firebase';

import { useDispatch } from 'react-redux';
import AppSlice from "@store/app";

export function useShowHeader(showHeader) {
  const dispatch = useDispatch();

  useEffect(() => {
    const user = firebase.auth().currentUser;
    if (showHeader === 'false') {
      dispatch(AppSlice.actions.setHeader({ visible: false, showHamburger: false }));
    } else if (user) {
      dispatch(AppSlice.actions.setHeader({ visible: true, showHamburger: true }));
    } else {
      dispatch(AppSlice.actions.setHeader({ visible: true, showHamburger: false }));
    }
  }, [showHeader])
}
