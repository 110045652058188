import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IoClose } from "react-icons/io5";
import PageSpinner from '@components/PageSpinner/PageSpinner';
import { ReactComponent as ModalEmail } from '@assets/svg/ModalMail.svg';
import { parseText } from '@utils/appUtils';
import firebase from 'firebase';
import './ContactUsForm.css';

const ContactUsForm = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const userId = useSelector(state => state?.firebase?.auth?.uid);
  const definedEmailBody = useSelector(state => state?.app?.definedEmailBody);
  const emailAddressFrom = useSelector(state => state.firebase?.auth?.email);
  
  const formValues = useSelector(state => {
    return {
      userEmail: state?.firestore?.data?.users?.[userId]?.email,
      userFullName: state?.firestore?.data?.users?.[userId]?.name + ' ' + state?.firestore?.data?.users?.[userId]?.surname
    }
  });
  
  //hello@simple-revolution.com
  const [email, setEmail] = useState({
    receivers: 'hello@simple-revolution.com',
    subject: 'Rippl get in touch form',
    emailAddressFrom: emailAddressFrom,
    body: parseText(definedEmailBody, formValues)
  });

  const [emailStatus, setEmailStatus] = useState({
    trigeredSend: false,
    sent: false
  })

  console.log(emailStatus);

  const sendEmail = firebase.functions().httpsCallable('utils-sendEmail');
  const doSendEmail = () => {
    setLoading(true);
    sendEmail(email).then(res => {
      if (res.data) {
        setEmailStatus({
          trigeredSend: true,
          sent: true
        })
      } else {
        setEmailStatus({
          trigeredSend: true,
          sent: false
        })
      }
      setLoading(false);
    });
  }

  return (
    <div className='contact-form-container'>
      {
        loading 
          ? <PageSpinner />
          : !emailStatus.trigeredSend 
              ?<div>
                  <div className="closeModal">
                    <IoClose  onClick={onClose} />
                  </div>

                  <div className='info-text-container'>
                    <p>
                      Currently you don't have access to this section. <br /> 
                      Please get in touch if you wish to unlock it or get further coaching.
                    </p>
                  </div>
                  <div className='form-wrapper'>
                    <div className='email-top-section'>
                      <span>To: </span>
                      <span>{email.receivers}</span>
                    </div>
                    <div className='email-bottom-section'>
                      <textarea 
                        onChange={(e) => setEmail({...email, body: e.target.value})}
                        value={email.body}
                      />
                    </div>
                  </div>

                  <div className='form-send-button' onClick={doSendEmail}>
                    Send
                  </div>
                </div>
              : emailStatus.sent 
                ? <div className="server-response-status">
                    <ModalEmail />
                    <h1>Email sent successfully.<br /> We will contact you shortly.</h1>
                    <button onClick={onClose}>Close</button>
                  </div>
                : <div className="server-response-status">
                    <ModalEmail />
                    <h1>Failed to send email. Please try again later.</h1>
                    <button onClick={onClose}>Close</button>
                  </div>
      }
    </div>
  )
}

export default ContactUsForm
