import React, { useState, useEffect } from 'react';
import './SlideToggle.css';

const SlideToggle = ({
  text,
  defaultStateOn,
  handleToggle,
  autoToggleOn,
  autoToggleOff
}) => {
  const [isToggledOn, setIsToggledOn] = useState(defaultStateOn);

  const handleSlideToggle = (state) => {
    setIsToggledOn(state)
    if (typeof handleToggle === 'function') handleToggle(state);
  }

  useEffect(() => {
    if (autoToggleOn) {
      handleSlideToggle(true);
    } else if (autoToggleOff) {
      handleSlideToggle(false);
    }
  }, [autoToggleOn, autoToggleOff])

  useEffect(() => {
    setIsToggledOn(defaultStateOn);
  }, [defaultStateOn])

  return (
    <div className="pannel-control">
      <div
        onClick={() => handleSlideToggle(!isToggledOn)}
        className={`slide-toggle ${isToggledOn ? 'slide-toggle-on' : ''}`}
      >
        <div className={`toggle-button ${isToggledOn ? 'toggle-button-on' : ''}`} />
      </div>
      <p>{text}</p>
    </div>
  )
}

export default SlideToggle