import React from 'react';
import { v4 as uuid } from 'uuid';
import { IoChevronUp } from "react-icons/io5";
import $ from 'jquery';
import './ExpandableDiv.css';

const ExpandableDiv = ({ header, content, time }) => {
    const uniqueClass = uuid();
    let isExpanded = false;

    const toggleContent = () => {
        $(`.${uniqueClass}`).slideToggle(400);
        if (isExpanded) {
            $(`.expand-chavron-${uniqueClass}`).removeClass('rotate-down');
            $(`.expand-chavron-${uniqueClass}`).addClass('rotate-up');
            $(`.label-button-${uniqueClass}`).text('learn more')
            isExpanded = false;
        } else {
            $(`.expand-chavron-${uniqueClass}`).removeClass('rotate-up');
            $(`.expand-chavron-${uniqueClass}`).addClass('rotate-down');
            $(`.label-button-${uniqueClass}`).text('collapse');
            isExpanded = true;
        }
    }

    const headerElement = React.createElement(
        header.type,
        {...header.props},
        [
            header.props.children, 
            <span 
                key={uuid()}
                className="view-more-span"
                onClick={toggleContent}
            >
                <span className={`label-button label-button-${uniqueClass}`}>
                    learn more
                </span>
                <IoChevronUp className={`expand-chavron-${uniqueClass}`} />
            </span>
        ],
    )

    return (
        <div className="expandable-div-container">
            <div className="expandable-div-header">
                {headerElement}
            </div>
            <div className={`expandable-div-content ${uniqueClass}`}>
                {content}
            </div>
        </div>
    );
};

export default ExpandableDiv;
