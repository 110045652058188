import { createSlice } from '@reduxjs/toolkit'

import firebase from "firebase";

export const initConference = async (dispatch, conferenceId) => {
  const conferenceDocSnap = await firebase.firestore()
    .collection('conference')
    .doc(conferenceId)
    .get();

  const conferenceData = conferenceDocSnap.data();

  if (!conferenceData) {
    return;
  }

  // dispatch(setConferenceId(conferenceId));
  // dispatch(setConferenceJobs(conferenceData.jobs));
  dispatch(setConferenceData({
    id: conferenceId,
    name: conferenceData.name,
    conferenceJobs: conferenceData.jobs,
    ages: conferenceData.ages || [],
    genders: conferenceData.genders || [],
    isSimpleRevolution: conferenceData.isSimpleRevolution,
    hasAdditionalSurvey: conferenceData.hasAdditionalSurvey
  }))
}

const ConferenceSlice = createSlice({
  name: 'conference',
  initialState: {
    conferenceId: '',
    conferenceJobs: [],
    name: '',
    ages: [],
    genders: [],
    showedUserNumber: false,
    isSimpleRevolution: false,
    hasAdditionalSurvey: false
  },
  reducers: {
    // setConferenceId: (state, action) => {
    //   state.conferenceId = action.payload;
    // },
    // setConferenceJobs: (state, action) => {
    //   state.conferenceJobs = action.payload;
    // },
    setConferenceData: (state, action) => {
      state.conferenceId = action.payload.conferenceId;
      state.conferenceJobs = action.payload.conferenceJobs;
      state.name = action.payload.name;
      state.ages = action.payload.ages;
      state.genders = action.payload.genders;
      state.isSimpleRevolution = action.payload.isSimpleRevolution;
      state.hasAdditionalSurvey = action.payload.hasAdditionalSurvey;
    },
    setShowedUserNumber: (state, action) => {
      state.showedUserNumber = action.payload;
    }
  },
})

export const {
  // setConferenceId,
  // setConferenceJobs,
  setConferenceData,
  setShowedUserNumber
} = ConferenceSlice.actions

export default ConferenceSlice.reducer