import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoClose } from "react-icons/io5";
import { useSelector } from 'react-redux';
import firebase from 'firebase';

import { ReactComponent as ModalAttachment } from '@assets/svg/ModalAttachment.svg';
import { ReactComponent as ModalEmail } from '@assets/svg/ModalMail.svg';
import { ReactComponent as ModalSend } from '@assets/svg/ModalSend.svg';
import { isEmailValid, parseText, sendPdf } from '@utils/appUtils';

import './EmailModal.css';

const EmailModal = ({ inviteFriend, shareResults, onClose }) => {
  const { i18n } = useTranslation();
  const userId = useSelector(state => state.firebase.auth.uid);
  const userEmail = useSelector(state => state.firebase.auth.email);
  const userName = useSelector(state =>
    state?.firestore?.data?.users?.[userId]?.name + ' ' + state?.firestore?.data?.users?.[userId]?.surname
  );
  const inviteFriendEmail = useSelector(state => state?.app?.inviteFriendEmail);
  const shareResultsEmail = useSelector(state => state?.app?.shareResultsEmail);

  const [loading, setLoading] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [friendEmail, setFriendEmail] = useState({
    value: '',
    sentStatus: false,
    valid: false
  })

  const inviteFriendEmailBodyValues = {
    name: 'Rippl User',
    link: 'https://rippl-staging.web.app/login'
  }
  const inviteFriendEmailSubjectValues = {
    name: userName.toUpperCase()
  }

  const shareResultsEmailValues = {
    name: userName.toUpperCase(),
    email: userEmail,
    ourContactEmail: 'our_contact@email.com'
  }

  const handleInputChange = (e) => {
    setFriendEmail({
      ...friendEmail,
      value: e.target.value,
      valid: isEmailValid(e.target.value)
    });
  }

  const shareAppLink = firebase.functions().httpsCallable('utils-sendEmail');
  const doShareAppLink = () => {
    setLoading(true);
    shareAppLink({
      receivers: [friendEmail.value],
      subject: parseText(inviteFriendEmail.subject, inviteFriendEmailSubjectValues),
      body: parseText(inviteFriendEmail.template, inviteFriendEmailBodyValues)
    }).then(res => {
      setFriendEmail({
        ...friendEmail,
        value: '',
        sentStatus: res.data.friendEmailStatusSent
      });
      setLoading(false);
    });
  }

  const doGeneratePdf = () => {
    setLoading(true);
    try {
      sendPdf({
        firebase: firebase,
        userId: userId,
        pathToTemplate: 'cStyleResultsTemplate/cStyleResultsTemplate',
        email: userEmail,
        language: i18n.language,
        textTemplate: shareResultsEmail.template,
        textValues: shareResultsEmailValues,
        subjectTemplate: shareResultsEmail.subject,
        subjectValues: shareResultsEmailValues
      }).then(res => {
        console.log(res);
        setEmailStatus(res.data.emailSentStatus);
        setLoading(false);
      })
    } catch (err) {
      setEmailStatus(false);
      setLoading(false);
    }
  }

  return (
    <div className="invite-modal-conatiner">
      <div className="closeModal">
        <IoClose onClick={onClose} />
      </div>
      {
        loading
          ? <div className="loading-container">
            <div className="loading-div"></div>
            <span>processing...</span>
          </div>

          : <div className="modal-body">
            <div className="icon-container">
              {
                shareResults
                  ? <ModalAttachment />
                  : <ModalEmail />
              }
            </div>
            {
              shareResults
                ? <div className="email-text-container">
                  {
                    emailStatus
                      ? <div>
                        <h1>Your results were successfully sent</h1>
                        <button onClick={onClose}>Close</button>
                      </div>

                      : <div>
                        <h1>Your results will be sent to {userEmail}</h1>
                        <button onClick={doGeneratePdf}>
                          <span>Send</span>
                          <ModalSend />
                        </button>
                      </div>
                  }
                </div>

                : <div className="email-text-container">
                  {
                    friendEmail.sentStatus
                      ? <div>
                        <h1>Invite sent successfully</h1>
                        <button onClick={onClose}>Close</button>
                      </div>

                      : <div>
                        <h1>Enter your friend's email address below to invite them to take the C-style test</h1>
                        <div className="input-container">
                          <input type="text" onChange={handleInputChange} />
                          {
                            friendEmail.valid
                              ? <ModalSend onClick={doShareAppLink} />
                              : <ModalSend style={{ opacity: '.2' }} />
                          }
                        </div>
                      </div>
                  }
                </div>
            }
          </div>
      }
    </div>
  )
}

export default EmailModal;

export const SendMyResultsModal = ({ onClose }) => {
  const { i18n } = useTranslation();
  const userId = useSelector(state => state.firebase?.auth?.uid);
  const shareResultsEmail = useSelector(state => state?.app?.shareResultsEmail);
  const [userEmail, setUserEmail] = useState('');
  const [emailOk, setEmailOK] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleInputChange = (e) => {
    setUserEmail(e.target.value);
    if (isEmailValid(userEmail)) {
      setEmailOK(true);
    } else {
      setEmailOK(false);
    }
  }

  const shareResultsEmailValues = {
    email: userEmail,
    ourContactEmail: 'our_contact@email.com'
  }

  const doGeneratePdf = () => {
    setLoading(true);
    try {
      sendPdf({
        firebase: firebase,
        userId: userId,
        pathToTemplate: 'cStyleResultsTemplate/cStyleResultsTemplate',
        email: userEmail,
        language: i18n.language,
        textTemplate: shareResultsEmail.template,
        textValues: shareResultsEmailValues,
        subjectTemplate: shareResultsEmail.subject,
        subjectValues: shareResultsEmailValues
      }).then(res => {
        console.log(res);
        setEmailSent(true);
        setLoading(false);
      })
    } catch (err) {
      console.log(err);
      setEmailSent(false);
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="send-my">
        <div className="loading-container">
          <div className="loading-div"></div>
          <span>processing...</span>
        </div>
      </div>
    )
  }

  if (emailSent) {
    return (
      <div className="send-my">
        <div className="send-my__results">
          <div className="send-my__results__close">
            <IoClose onClick={onClose} />
          </div>

          <ModalAttachment className="send-my__results__attachment" />

          <div className="send-my__results__text">
            <h1 className="send-my__results__text__primary">
              Email successfully sent.
            </h1>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="send-my">
      <div className="send-my__results">
        <div className="send-my__results__close">
          <IoClose onClick={onClose} />
        </div>

        <ModalAttachment className="send-my__results__attachment" />

        <div className="send-my__results__text">
          <h1 className="send-my__results__text__primary">
            Please insert your email.
          </h1>
        </div>

        <div className="send-my__results__email-box">
          <input type="text" onChange={handleInputChange} value={userEmail} />
          {emailOk
            ? <ModalSend onClick={doGeneratePdf} />
            : <ModalSend style={{ opacity: '.2' }} onClick={() => alert('plm')} />
          }
        </div>
      </div>
    </div>
  )
}