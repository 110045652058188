import React from 'react'
import { useDispatch } from 'react-redux'
import { IoPlay } from 'react-icons/io5'
import { playVideo } from '@utils/appUtils'

import ConnectingCircle from '@assets/recommended_videos/RoundGradConnecting.svg'
import InfluencingCircle from '@assets/recommended_videos/RoundGradInfluencing.svg'
import ExaminingCircle from '@assets/recommended_videos/RoundGradExamining.svg'
import DeliveringCircle from '@assets/recommended_videos/RoundGradDelivering.svg'

import ConnectingPicture from '@assets/recommended_videos/cStyleConnecting.png'
import InfluencingPicture from '@assets/recommended_videos/cStyleInfluencing.png'
import ExaminingPicture from '@assets/recommended_videos/cStyleExamining.png'
import DeliveringPicture from '@assets/recommended_videos/cStyleDelivering.png'
import AssertivenessConnecting from '@assets/recommended_videos/AssertivenessConnecting.png'
import AssertivenessInfluencing from '@assets/recommended_videos/AssertivenessInfluencing.png'
import AssertivenessExamining from '@assets/recommended_videos/AssertivenessExamining.png'
import AssertivenessDelivering from '@assets/recommended_videos/AssertivenessDelivering.png'

const RecommendedVideo = ({ circle, insidePicture, videoLink, pText, h1Text, name, playedFrom }) => {
    const dispatch = useDispatch();

    const circles = {
        'ConnectingCircle': ConnectingCircle,
        'InfluencingCircle': InfluencingCircle,
        'ExaminingCircle': ExaminingCircle,
        'DeliveringCircle': DeliveringCircle,

    }
    const pictures = {
        'ConnectingPicture': ConnectingPicture,
        'InfluencingPicture': InfluencingPicture,
        'ExaminingPicture': ExaminingPicture,
        'DeliveringPicture': DeliveringPicture,
        'AssertivenessConnecting': AssertivenessConnecting,
        'AssertivenessInfluencing': AssertivenessInfluencing,
        'AssertivenessExamining': AssertivenessExamining,
        'AssertivenessDelivering': AssertivenessDelivering
    }

    return (
        <div className="video-container">
            <div onClick={() => playVideo(dispatch, videoLink, name, playedFrom)}
                style={{ 'backgroundImage': `url('${circles[`${circle}`]}')` }}
                className="round-gradient"
            >
                <div style={{ 'backgroundImage': `url('${pictures[`${insidePicture}`]}')` }} className="cstyle-picture">
                </div>
                <IoPlay />
            </div>
            <div className="video-labels-container">
                <p>{pText}</p>
                <h1>{h1Text.toUpperCase()}</h1>
            </div>
        </div>
    )
}

export default RecommendedVideo;
