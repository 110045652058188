import React, { Children, useEffect } from 'react'
import { v4 as uuid } from 'uuid';
import $ from 'jquery';
import './SI_ThirdPage.css'
import PageWrapper from '@components/PageWrapper';
import ReadingCard from '@components/ReadingCard/ReadingCard';

const ContainerWithHeader = ({ children, header = null }) => {

  useEffect(() => {
    $('.survey-component__survey .sv_main').addClass('overWriteSurvey');
    $('.survey-component__survey .sv_body').css('line-height', 'normal');

    return () => {
      $('.survey-component__survey .sv_main').removeClass('overWriteSurvey');
    }
  }, [])

  return (
    <div className="qna-container">
      <PageWrapper>
        {
          header && <div className="qna-text-container">
            <h1>{header.title}</h1>
            <p>{header.subtitle}</p>
          </div>
        }
        {children}
      </PageWrapper>
    </div>
  )
}

export default ContainerWithHeader;