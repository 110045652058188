
import { useTranslation } from 'react-i18next';
import './Graph.css';

export const FullDot = ({ left, top }) => {
  const { t } = useTranslation();

  return (
    <div className="dot-container"
      style={{ left, top }}
    >
      <div className="dot big-dot animate-dot"></div>
      <div id="user-dot-possition" className="dot-here-text">{t('graph.youAreHere')}</div>
    </div>
  )
}

export const MiniDot = ({ left, top, pulse, number, dotColor }) => {
  const getZindex = () => {
    if (pulse) {
      return 2;
    } else if (dotColor) {
      return 1;
    } else {
      return 0;
    }
  }

  return (
    <div className="mini-dot-container"
      style={{
        left,
        top,
        zIndex: getZindex()
      }}
    >
      <div
        style={{
          backgroundColor: dotColor || 'rgba(189, 184, 168, .8)'
        }}
        className={`dot mini-dot ${pulse ? 'animate-dot go-big-dot' : ''}`}
      >
        {pulse && <span className='dot-user-number'>{number}</span>}
      </div>
    </div>
  )
}