import React from 'react';
import './RadioButton.css';

const RadioButton = ({ index, name, handleClick, selected }) => {
  return (
    <div
      className='radio-button'
      onClick={() => handleClick(index, name)}
    >
      <div className='radio-button__box'>
        <div className={`${selected ? 'radio-button__box--selected' : ''}`} />
      </div>
      <span>{name}</span>
    </div>
  )
}

export default RadioButton