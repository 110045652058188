import React from 'react';
import './ReadingCard.css';

const ReadingCard = ({ question, answer, q, a, children }) => {
  // question: string
  // answer: string
  // q: string
  // a: string
  // children: jsx elements

  return (
    <div className="qna-card-container">
      <div className="question-container">
        <h1>
          {q && <span>{q} - </span>}
          {question}
        </h1>
      </div>

      <div className="answer-container">
        {answer && <h1>
          {a && <span>{a} - </span>}
          {answer}
        </h1>}
        {children}
      </div>
    </div>
  )
}

export default ReadingCard