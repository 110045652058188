import React from 'react'
import './PieChartsContainer.css';

const PieChartsContainer = ({ children }) => {
  return (
    <div className='pie-charts-container'>
      {children}
    </div>
  )
}

export default PieChartsContainer