import React, { useState, useEffect, useRef } from 'react';
import './AwesomeFlipCard.css';

function AwesomeFlipCard({ children }) {
  const [isFlipped, setIsFlipped] = useState(false);
  const cardContainer = useRef();

  function handleFlip() {
    setIsFlipped(isFlipped => !isFlipped);
  }

  useEffect(() => {
    const cardFaces = document.querySelectorAll('.card-face');
    const frontFaces = document.querySelectorAll('.face-front');
    const backFaces = document.querySelectorAll('.face-back');

    let maxHeight = 0;
    frontFaces.forEach(face => {
      const faceHeight = face.getBoundingClientRect().height;
      if (faceHeight > maxHeight) {
        maxHeight = faceHeight
      };
    })

    backFaces.forEach(face => {
      const faceHeight = face.getBoundingClientRect().height;
      if (faceHeight > maxHeight) {
        maxHeight = faceHeight
      };
    })
    cardContainer.current.style.height = maxHeight + "px";

    for (let i = 0; i < cardFaces.length; i++) {
      cardFaces[i]?.classList.add('height100');
    }
  }, [children])

  return (
    <div ref={cardContainer} className='card' onClick={handleFlip}>
      <div className={`card-inner ${isFlipped ? 'isFlipped' : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default AwesomeFlipCard