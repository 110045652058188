import React from "react";
import { v4 as uuid } from 'uuid';
import { useDispatch } from "react-redux";
import { playVideo } from "@utils/appUtils";
import CustomVideoPlayer from "@components/Video/CustomVideoPlayer";
import "./style.css";

const Video = ({ children, className }) => {
    const dispatch = useDispatch();
    const {
        src,
        frameborder,
        allowfullscreen,
        allow,
        width,
        height,
        frameBorder,
        allowFullScreen,
        name
    } = children?.props;

    return (
        <>
            <div
                className="video-wrapper"
                onClick={() => playVideo(dispatch, src, name, 'Introduction component')}
            >
                <div className="video-wrapper__overlay" />
                <iframe
                    title={uuid()}
                    src={src}
                    frameBorder={frameborder || frameBorder}
                    allowFullScreen={allowfullscreen || allowFullScreen}
                    allow={allow}
                    width={width}
                    height={height}
                ></iframe>
            </div>
            <CustomVideoPlayer />
        </>
    );
};

export default Video;
