import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import firebase from "firebase";

// redux
import { initConference, setShowedUserNumber } from "@store/conference";
import { useDispatch } from "react-redux";
import AppSlice from "@store/app";

// components
import { PrimaryButton, SecondaryButton } from "@components/Buttons/Buttons";
import JobOptions from "@components/JobOptions/JobOptions";

// utils
import { updateConferenceResult, isArray } from "@utils/appUtils";

// icons
import { ReactComponent as SendEmail } from "@assets/svg/EmailButton.svg";

// style
import './LiveConferencePreSurvey.css';

function LiveConferencePreSurvey({ uid, conference, userNumber, showedUserNumber, conferenceJobs }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [secondaryBtnDisabled, setSecondaryBtnDisabled] = useState(true);
  const [timer, setTimer] = useState(6);

  useEffect(() => {
    function updateUserDidShowInterScreen() {
      firebase.firestore()
        .collection('users')
        .doc(uid)
        .update({
          didShowInterScreen: true
        })
    }
    updateUserDidShowInterScreen();
  }, [])

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer => timer - 1);
      }, 1000);
    } else {
      dispatch(setShowedUserNumber(true));
    }
  }, [timer])

  useEffect(() => {
    let unsubscribeConferenceListener;
    if (conference) {
      unsubscribeConferenceListener = firebase.firestore()
        .collection("conference")
        .doc(conference)
        .onSnapshot(docSnap => {
          const data = docSnap.data();
          setSecondaryBtnDisabled(!data.allowSeeResults);
        })
    }

    return () => {
      if (typeof unsubscribeConferenceListener === "function") {
        unsubscribeConferenceListener()
      }
    };
  }, [conference]);

  useEffect(() => {
    if (isArray(conferenceJobs) && conference) {
      initConference(dispatch, conference);
    }
  }, [conferenceJobs, conference])

  const displayModal = () => {
    dispatch(AppSlice.actions.setModal({
      visible: true,
      content: {
        type: 'sendMyResults'
      }
    }))
  }

  return (
    <div className="inter-screen-container">
      <div className="inter-screen">
        <h1 className="inter-screen__header">
          Thank you for completing the survey.
        </h1>

        <div className="inter-screen__timer">
          {!showedUserNumber &&
            <div className="inter-screen__timer--in">
              <h1>Your results will appear on the big screen in {timer}.</h1>
              <h1>Look out for pin number <span className="inter-screen__timer__user-number"><b>{userNumber}</b></span></h1>
            </div>
          }
          {showedUserNumber &&
            <div className="inter-screen__timer--out">
              <h1>
                Look up! You're on the big screen now.
                <br />
                Your number is <span className="inter-screen__timer__user-number"><b>{userNumber}</b></span>.
              </h1>
            </div>
          }
        </div>

        <div className="inter-screen__job-survey">
          <h1 className="inter-screen__job-survey__title">
            We would love to know what you do. Choose the type of job that get's closest to what you do.
          </h1>
          <JobOptions>
            {Object.keys(conferenceJobs).map((jobTitle, index) => {
              return <JobOptions.Job
                key={index + jobTitle}
                name={jobTitle}
              />
            })}
          </JobOptions>
        </div>

        <div className="bottom-btns-container">
          <PrimaryButton
            onClick={() => updateConferenceResult(uid, conference, { pulse: true })}
          >
            PULSE {userNumber}
          </PrimaryButton>

          <SecondaryButton
            onClick={() => history.push("/c-style")}
            disabled={secondaryBtnDisabled}
          >
            See your results
          </SecondaryButton>

          <SecondaryButton
            onClick={displayModal}
            additionalClasses='envelope-btn'
          >
            <SendEmail />
            Email results
          </SecondaryButton>
        </div>

      </div>
    </div>
  )
}

export default LiveConferencePreSurvey