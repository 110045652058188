import React from 'react';
import PieChart from '../PieChart/PieChart.js';
import { v4 as uuid } from 'uuid';
import JobLegend from '@components/JobLegend/JobLegend.js';
import './PieChartWithCustomLegend.css';

const PieChartWithCustomLegend = ({ label, data, conferenceJobs }) => {
  const total = data.total;
  const labelsWithPercentsAndColors = [];
  const backgroundColors = [];
  const borderColors = [];

  Object.entries(data.jobs).forEach(entry => {
    const jobTitle = entry[0];
    const numbers = entry[1];

    backgroundColors.push(
      conferenceJobs[jobTitle]?.backgroundColor
    );
    borderColors.push(
      conferenceJobs[jobTitle]?.borderColor
    );

    const percent = (100 / (total / numbers)).toFixed(0)
    labelsWithPercentsAndColors.push({
      text: `${jobTitle} ${percent}%`,
      color: conferenceJobs[jobTitle]?.backgroundColor
    })
  })

  const chartData = Object.entries(data.jobs).map(([, value]) => value);

  const jobsLegendElements = labelsWithPercentsAndColors.map(label => {
    const key = uuid();
    return (
      <JobLegend
        key={key}
        text={label.text}
        color={label.color}
      />
    )
  })

  return (
    <div className='chart-with-legend'>
      <label>{label}</label>
      <div className='chart-group'>
        <div className='chart-container'>
          <PieChart
            data={chartData}
            backgroundColors={backgroundColors}
            borderColors={borderColors}
          />
        </div>

        <div className='legends-container'>
          {jobsLegendElements}
        </div>
      </div>
    </div>
  )
}

export default PieChartWithCustomLegend