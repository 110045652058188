import React from "react";
import * as Survey from "survey-react";
import JsxParser from "react-jsx-parser";
import AssertiveResultsChart from "@components/AssertiveResultsChart";
//import SwipeCards from "@components/SwipeCards";
//import Card from "@components/Card";
//import SwipeThrough from "@components/SwipeThrough";
import ReactCardFlip from "react-card-flip";
import FlipCards from "@components/FlipCards";
import Tip from "@components/Tip";
import Introduction from "@components/Introduction";
import Instructions from "@components/Instructions";
import Pagination from "@components/Pagination";
import ReadingCard from "@components/ReadingCard/ReadingCard";
import Radio from "@components/Radio";
import List from "@components/List/List";
import PWCGraph from "@components/PWCGraph/PWCGraph";
import SI_FirstPage from "@components/SI_FirstPage/SI_FirstPage";
import SI_SecondPage from "@components/SI_SecondPage/SI_SecondPage";
import SI_ThirdPage from "@components/SI_ThirdPage/SI_ThirdPage";
import TrueOrFalse from "@components/TrueOrFalse/TrueOrFalse";
import AwesomeFlipCard from "@components/AwesomeFlipCard/AwesomeFlipCard";
import CardsContainer from "@components/CardsContainer/CardsContainer";

const SCORE_BY_POSITION = [5, 3, 1, 0];
const SCORE_TOTAL_PER_QUESTION = SCORE_BY_POSITION.reduce((a, b) => a + b, 0);

const QuestionContext = React.createContext({});
QuestionContext.displayName = "QuestionContext";

export { QuestionContext };

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "AssertiveResultsChart",
        title: "AssertiveResultsChart",
        isFit: (question) => {
            return (
                // question.getType() === "results" ||
                question.getType() === "html" &&
                question.html.indexOf("<AssertiveResultsChart") > 0
            );
        },
        render: (question) => {

            // for keeping max score which can be achieved, currently 72 for 8 questions
            let totalScore = 0;
            // search for pages with "Ranking" type questions only
            const rankingQuestions = [];
            question.data.pages.forEach((page) => {
                // check if the first element is a QuestionRankingModel
                if (page.elements?.[0] instanceof Survey.QuestionRankingModel) {
                    rankingQuestions.push(page.elements?.[0]);
                    // sum up totalScore for ranking questions per course
                    totalScore += SCORE_TOTAL_PER_QUESTION;
                }
            });

            // build a lookup table for determining values based on label
            // this is a workaround for SurveyJS bug
            // where Ranking type questions data is stored as text labels only
            const questionsHash = rankingQuestions.reduce((acc, rQ) => {
                const choicesHash = {};
                (rQ.originalChoices || []).forEach(({ text, value }) => {
                    choicesHash[text.trim()] = value.trim().toLowerCase(); // store sanitized
                });

                // store per-name choicesHash
                acc[rQ.name] = choicesHash;

                return acc;
            }, {});


            // initialize scores
            // keys are taken from ranking-questions choices "value" field
            const scores = {
                passive: 0,
                "passive/aggressive": 0,
                aggressive: 0,
                assertive: 0,
            };
            const percents = {};

            // get the actual results data
            const data = question.data.data;
            // iterate through stored data
            // {
            //   "question1": ["Label1", "Label text2", "LabelText 3"]
            // }
            for (const [questionName, answers] of Object.entries(data)) {
                // find value (passive, assertive, etc) and assign score for position
                answers.forEach((answerText, idx) => {
                    const answerValue = questionsHash[questionName][
                        answerText
                    ]?.toLowerCase();

                    scores[answerValue] += SCORE_BY_POSITION[idx];
                });

            }

            for (let [type, score] of Object.entries(scores)) {
                type = type.replace("/a", "A"); // passive/aggressive to camelCase
                percents[type] = Math.round((score / totalScore) * 100);
            }

            return (
                <JsxParser
                    autoCloseVoidElements
                    className="assertive-results-page"
                    bindings={{
                        value: {
                            scores,
                            percents,
                            totalScore,
                            count: rankingQuestions.length,
                        },
                        ...percents,
                    }}
                    components={{ AssertiveResultsChart }}
                    jsx={question.html}
                />
            );
        },
        activatedByChanged: function (activatedBy) {
            Survey.JsonObject.metaData.addClass("results", [], null, "empty");
            Survey.JsonObject.metaData.addProperty("ranking", {
                name: "originalChoices",
                default: {},
            });
            Survey.JsonObject.metaData.addProperties("results", [
                { name: "customData", default: {} },
            ]);
            Survey.JsonObject.metaData.addProperties("html", [
                { name: "customData", default: {} },
            ]);
        },
    },
    "customtype"
);

//let cards = [];

/*
Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "SwipeCards",
        title: "SwipeCards",
        isFit: (question) => {
            return (
                question.getType() === "radiogroup" &&
                question.behavior === "swipe"
            );
        },

        render: (question) => {
            const questions = question.data.getAllQuestions();

            const index = questions.indexOf(question);

            const isLastQuestion =
                questions[index + 1].getType() !== "radiogroup";

            function checkFinished() {
                if (isLastQuestion) {
                    setTimeout(() => {
                        question.data.nextPage();
                    }, 500);
                }
            }

            function onSwipeRight() {
                question.value = "agree";
                checkFinished();
            }

            function onSwipeLeft() {
                question.value = "disagree";
                checkFinished();
            }

            if (questions[index - 1].getType() !== "radiogroup") {
                cards = [];
            }

            if (!cards.some((c) => c.index === index)) {
                cards.push({
                    index,
                    title: question.title,
                    onSwipeLeft,
                    onSwipeRight,
                });
            }

            if (isLastQuestion) {
                return (
                    <SwipeCards>
                        {cards.map((c, i) => {
                            return (
                                <Card
                                    style={{ position: "absolute" }}
                                    key={i}
                                    onSwipeRight={c.onSwipeRight}
                                    onSwipeLeft={c.onSwipeLeft}
                                >
                                    {c.title}
                                </Card>
                            );
                        })}
                    </SwipeCards>
                );
            } else {
                return false;
            }
        },
        htmlTemplate: "",
        activatedByChanged: function (activatedBy) {
            Survey.JsonObject.metaData.addProperties("question", [
                { name: "behavior", default: "regular" },
            ]);
        },
    },
    "customtype"
);
*/

/*
Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "SwipeThrough",
        title: "SwipeThrough",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.html.indexOf("<SwipeThrough") > 0
            );
        },
        render: (question) => {
            const answers = question.data
                .getAllQuestions()
                .filter((q) => q.getType() === "radiogroup");

            const complete = () => {
                setTimeout(() => {
                    question.data.nextPage();
                }, 500);
            };

            return (
                <JsxParser
                    autoCloseVoidElements
                    className="swipe-through"
                    bindings={{ answers, complete }}
                    components={{ SwipeThrough, Card }}
                    jsx={question.html}
                    onError={(e) => {
                        console.log(e);
                    }}
                />
            );
        },
    },
    "customtype"
);
*/

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "FlipCards",
        title: "FlipCards",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.html.indexOf("<FlipCards>") >= 0 &&
                question.html.indexOf("<FlipCards>") < 5
            );
        },
        render: (question) => {
            return (
                <JsxParser
                    autoCloseVoidElements
                    className="flip-cards"
                    components={{ FlipCards, ReactCardFlip }}
                    jsx={question.html}
                    onError={(e) => {
                        console.log(e);
                    }}
                />
            );
        },
    },
    "customtype"
);

// Survey.CustomWidgetCollection.Instance.addCustomWidget(
//     {
//         name: "Tip",
//         title: "Tip",
//         isFit: (question) => {
//             return (
//                 question.getType() === "html" &&
//                 question.html.indexOf("<Tip") !== -1
//             );
//         },
//         render: (question) => {
//             const answers = {
//                 name: question.name,
//                 questions: question.data
//                     .getAllQuestions()
//                     .filter((q) => q.getType() === "radiogroup"),
//             };

//             return (
//                 <JsxParser
//                     autoCloseVoidElements
//                     className="tip"
//                     bindings={{ answers, complete: () => {} }}
//                     components={{ Tip }}
//                     jsx={question.html}
//                     onError={(e) => {
//                         console.log(e);
//                     }}
//                 />
//             );
//         },
//     },
//     "customtype"
// );

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "Introduction",
        title: "Introduction",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.html.indexOf("<Introduction") !== -1
            );
        },
        render: (question) => {
            return (
                <JsxParser
                    autoCloseVoidElements
                    className="introduction"
                    components={{ Introduction }}
                    jsx={question.html}
                    onError={(e) => {
                        console.log(e);
                    }}
                />
            );
        },
    },
    "customtype"
);

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "Instructions",
        title: "Instructions",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.html.indexOf("<Instructions") !== -1
            );
        },
        render: (question) => {
            const rankingExample = true;

            return (
                <JsxParser
                    autoCloseVoidElements
                    className="instructions"
                    bindings={{ rankingExample }}
                    components={{ Instructions, AwesomeFlipCard }}
                    jsx={question.html}
                    onError={(e) => {
                        console.log(e);
                    }}
                />
            );
        },
    },
    "customtype"
);

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "Pagination",
        title: "Pagination",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.html.indexOf("<Pagination") !== -1
            );
        },
        render: (question) => {
            const sortChildren = true;

            const answers = {
                name: question.name,
                questions: question.data
                    .getAllQuestions()
                    .filter((q) => q.getType() === "radiogroup"),
            };

            return (
                <QuestionContext.Provider value={question}>
                    <JsxParser
                        autoCloseVoidElements
                        className="pagination"
                        bindings={{ answers, sortChildren }}
                        components={{ Pagination, Tip }}
                        jsx={question.html}
                        onError={(e) => {
                            console.log(e);
                        }}
                    />
                </QuestionContext.Provider>
            );
        },
    },
    "customtype"
);

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "CstyleTip",
        title: "CstyleTip",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.html.indexOf("<Tip") !== -1
            );
        },
        render: (question) => {
            const cstyle = question.name;

            return (
                <JsxParser
                    autoCloseVoidElements
                    className="pagination"
                    bindings={{ cstyle }}
                    components={{ Tip }}
                    jsx={question.html}
                    onError={(e) => {
                        console.log(e);
                    }}
                />
            );
        },
    },
    "customtype"
);

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "Radio",
        title: "Radio",
        isFit: (question) => {
            return (
                question.getType() === "radiogroup" &&
                question.behavior === "custom-radio"
            );
        },

        render: (question) => {
            function onSelected(value) {
                question.value = value;
            }

            return <Radio onSelected={onSelected} question={question} />;
        },
        htmlTemplate: "",
        activatedByChanged: function (activatedBy) {
            Survey.JsonObject.metaData.addProperties("question", [
                { name: "behavior", default: "regular" },
            ]);
        },
    },
    "customtype"
);

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "PWCGraph",
        title: "PWCGraph",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.name === "PWCResults"
            );
        },

        render: (question) => {
            return <JsxParser
                bindings={{ question }}
                components={{ PWCGraph }}
                jsx={question.html}
            />
        },

        activatedByChanged: function (activatedBy) {
            Survey.JsonObject.metaData.addProperties("question", [
                { name: "behavior", default: "regular" },
            ]);
            Survey.JsonObject.metaData.addProperty("radiogroup", {
                name: "axis",
                default: {},
            });
        },
    },
    "customtype"
);


Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "si_firstPage",
        title: "si_firstPage",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.name === "si_firstPage"
            );
        },

        render: (question) => {
            return <QuestionContext.Provider value={question}>
                <JsxParser
                    bindings={{ question }}
                    components={{ SI_FirstPage }}
                    jsx={question.html}
                />
            </QuestionContext.Provider>
        },
    },
    "customtype"
);

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "si_secondPage",
        title: "si_secondPage",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.name === "si_secondPage"
            );
        },

        render: (question) => {
            return (
                <JsxParser
                    bindings={{ question }}
                    components={{ SI_SecondPage }}
                    jsx={question.html}
                />
            )
        },
    },
    "customtype"
);

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "si_thirdPage",
        title: "si_thirdPage",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.html.indexOf("<SI_ThirdPage") !== -1
            );
        },

        render: (question) => {
            return (
                <JsxParser
                    bindings={{ question }}
                    components={{ SI_ThirdPage, ReadingCard }}
                    jsx={question.html}
                />
            )
        },
    },
    "customtype"
);

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "List",
        title: "List",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.html.indexOf("<List") !== -1
            );
        },

        render: (question) => {
            return <JsxParser
                bindings={{ question }}
                components={{ List }}
                jsx={question.html}
            />
        },
        htmlTemplate: "",
        // activatedByChanged: function (activatedBy) {
        //     Survey.JsonObject.metaData.addProperties("question", [
        //         { name: "behavior", default: "regular" },
        //     ]);
        // },
    },
    "customtype"
);

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "TrueOrFalse",
        title: "TrueOrFalse",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.html.indexOf("<TrueOrFalse") !== -1
            );
        },

        render: (question) => {
            return <JsxParser
                bindings={{ question }}
                components={{ TrueOrFalse }}
                jsx={question.html}
            />
        },
        htmlTemplate: "",
    },
    "customtype"
);

Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
        name: "CardsContainer",
        title: "CardsContainer",
        isFit: (question) => {
            return (
                question.getType() === "html" &&
                question.html.indexOf("<CardsContainer") !== -1
            );
        },

        render: (question) => {
            return <JsxParser
                bindings={{ question }}
                components={{ CardsContainer, AwesomeFlipCard, FlipCards }}
                jsx={question.html}
            />
        },
        htmlTemplate: "",
        // activatedByChanged: function (activatedBy) {
        //     Survey.JsonObject.metaData.addProperties("question", [
        //         { name: "behavior", default: "regular" },
        //     ]);
        // },
    },
    "customtype"
);