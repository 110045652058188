import { useState, useEffect } from "react";
import { convertCoordinatesToPosition } from '@utils/convertFunctions';
import firebase from 'firebase';

export function useListenToConference(conferenceId, ripplUserId) {
  const [conferenceJobs, setConferenceJobs] = useState();
  const [conferenceResults, setConferenceResults] = useState();
  const [allJobsGrey, setAllJobsGrey] = useState(true);

  useEffect(function () {
    let unsubscribeResultsListener;
    if (conferenceId) {
      // Setup listener for current conference results
      unsubscribeResultsListener = firebase.firestore().collection('conference').doc(conferenceId).collection('results')
        .onSnapshot((querySnapshot) => {
          const results = querySnapshot.docs.map(doc => {
            const data = doc.data();
            const { job, pulse, cStyleName, coordinates, userNumber } = data;
            if (coordinates) {
              // Check if user has completed c-style
              const { top, left } = convertCoordinatesToPosition(coordinates);
              return {
                top,
                left,
                pulse,
                job,
                cStyleName,
                userNumber,
                showDotColor: doc.id === ripplUserId,
                dotColor: ripplUserId ? '#f86459' : null
              };
            }

            // We return null in case the user hasn't completed c-style
            // So we can take him off of the map with next filter call
            return null;
          })

          const resultsWithoutNull = results.filter(doc => doc !== null);
          setConferenceResults(resultsWithoutNull);
        });

      firebase.firestore().collection('conference')
        .doc(conferenceId).get()
        .then(docSnap => {
          const jobsWithCollorState = docSnap.data().jobs;

          if (!jobsWithCollorState) {
            return;
          }

          Object.keys(jobsWithCollorState).forEach(key => {
            jobsWithCollorState[key].showDotColor = !allJobsGrey;
          })
          setConferenceJobs(jobsWithCollorState);
        });
    } else {
      if (typeof unsubscribeResultsListener === 'function') {
        unsubscribeResultsListener();
      }
      setConferenceResults();
    }

  }, [conferenceId, ripplUserId])

  return {
    conferenceJobs,
    setConferenceJobs,
    conferenceResults,
    setConferenceResults,
    allJobsGrey,
    setAllJobsGrey
  };
}