import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import useTakeCourse from "./useTakeCourse"

const useCheckSkipIntroModal = () => {
    const history = useHistory();
    const { takeCourse, coursesInited } = useTakeCourse();

    const userId = useSelector(state => state.firebase.auth.uid);
    const skipIntroModal = useSelector(state => state.app.skipIntroModal);

    useFirestoreConnect([{ collection: "users", doc: userId }]);

    const user = useSelector(state => state.firestore?.data?.users?.[userId]);
    const [checkSkipIntroModalInited, setCheckSkipIntroModalInited] = useState(false);

    useEffect(() => {
        if (user && coursesInited) {
            setCheckSkipIntroModalInited(true);
        }
    }, [user, coursesInited])

    const checkSkipIntroModal = () => {
        if (user && !user.showedCStyle && coursesInited && skipIntroModal) {
            firebase.firestore().collection('users').doc(userId).update({
                showedCStyle: true
            })
            takeCourse(user?.mainCourse, 'take_course', 'Finding your C-Style');
        } else {
            history.push('/');
        }
    }

    return { checkSkipIntroModal, checkSkipIntroModalInited }
}

export default useCheckSkipIntroModal