import AppSlice from '@store/app';
import firebase from 'firebase';
import { analyticsLogEvent } from '@components/Analytics';

export const parseText = (template, values) => {
  Object.keys(values).forEach(key => {
    template = template.replace(`[@${key}@]`, values[key]);
  })
  return template;
}

export const isEmailValid = (email) => {
  const emailRegEx = new RegExp('.*@[a-z, 0-9]*..{1,}', 'gim');
  return (email.match(emailRegEx));
}

export const sendPdf = async ({ userId, pathToTemplate, email, language, textTemplate, textValues, subjectTemplate, subjectValues }) => {
  const generatePdf = firebase.functions().httpsCallable('generatePdfPuppeteer-generatePdfPuppeteer');

  return generatePdf({
    userId: userId,
    pathToTemplate: pathToTemplate,
    email: email,
    language: language,
    text: parseText(textTemplate, textValues),
    subject: parseText(subjectTemplate, subjectValues)
  })
    .then(res => res)
    .catch(err => err)
}

export const playVideo = (dispatch, src, name = '', playedFrom = '') => {
  analyticsLogEvent('play_video', { name: name, played_from: playedFrom })
  dispatch(AppSlice.actions.setVideoPlayer({
    source: src,
    isPlaying: true,
    name: name,
    playedFrom: playedFrom
  }))
}

export const stopVideo = (e, dispatch, video) => {
  e.stopPropagation()
  analyticsLogEvent('stop_video', { name: video.name })
  dispatch(AppSlice.actions.setVideoPlayer({
    source: '',
    isPlaying: false,
    name: '',
    playedFrom: ''
  }))
}

export const updateConferenceResult = async (uid, conference, updateObject) => {
  if (uid && conference) {
    firebase.firestore()
      .collection('conference')
      .doc(conference)
      .collection('results')
      .doc(uid)
      .update(updateObject)
  }
}

export const isObjectEmpty = (obj) => {
  console.log(obj);
  if (typeof obj === 'object') {
    return !Object.keys(obj).length > 0;
  } else {
    throw new Error(`isObjectEmpty function was called with wrong argument type. It accepts an object but was called with a type of ${typeof obj}`);
  }
}

export const isArray = (item) => {
  if (typeof item === 'object' && typeof item.length === 'number') {
    return true;
  }
  return false;
}

export function removeEmptySpacesFromString(string) {
  return string.replace(/[^a-zA-Z0-9]+/g, "_");
}

export function getAcronim(str) {
  return str.split(' ').map(word => word[0]).join('').toUpperCase();
}