import TopicsTile from "@components/TopicsTile";
import PageWrapper from "@components/PageWrapper";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useFirestore } from "react-redux-firebase";
import { ScrollableSectionWithBadge } from "@components/SectionWithBadge";
import ActionPanel from "@components/ActionPanel";
import { Link } from "react-router-dom";
import "./style.css";
import PodcastTile from "@components/PodcastTile";
import NotFound from "@components/NotFound";
import { analyticsLogEvent } from "@components/Analytics";
import { useTranslation } from "react-i18next";

const TopicsDetail = () => {
    const { topicId } = useParams();
    const userId = useSelector((state) => state.firebase.auth.uid);
    const history = useHistory();
    const firestore = useFirestore();
    const { t, i18n } = useTranslation();
    const language = i18n.language;

    useFirestoreConnect([
        {
            collection: "users",
            doc: userId,
            subcollections: [{ collection: "userCourses" }],
            storeAs: "myCourses",
        },
        {
            collection: "users",
            doc: userId,
            subcollections: [{ collection: "userPodcasts" }],
            storeAs: "myPodcasts",
        },
        { collection: "courses" },
    ]);

    const course = useSelector(
        (state) => state.firestore.data?.myCourses?.[topicId]
    );
    const allCourses = useSelector((state) => state.firestore.data?.courses);

    const [loggedToAnalytics, setLoggedToAnalytics] = useState(false);

    const courseDefinition = useSelector(
        (state) => state.firestore.data?.courses?.[topicId]
    );
    const relatedPodcasts = useSelector(
        (state) => state.firestore.data?.courses?.[topicId]?.podcasts
    );
    const myPodcasts = useSelector((state) => state.firestore.data?.myPodcasts);

    const goToCourse = useCallback((e) => history.push(`/course/${topicId}`), [
        history,
        topicId,
    ]);

    const handleCourse = async () => {
        if (course?.timeCompleted) {
            await firestore.update(
                {
                    collection: `users/${userId}/userCourses`,
                    doc: `${topicId}`,
                },
                {
                    results: firestore.FieldValue.delete(),
                    timeCompleted: null,
                    timeStarted: null,
                }
            );
            analyticsLogEvent('retake_course', {name: courseDefinition.name['en']})
            goToCourse();
        } else {
            analyticsLogEvent('take_course', {name: courseDefinition.name['en']})
            goToCourse();
        }
    };

    useEffect(() => {
        if (courseDefinition && courseDefinition.name && !loggedToAnalytics) {
            analyticsLogEvent('course_view', {name: courseDefinition?.name['en']});
            setLoggedToAnalytics(true);
        }
    }, [courseDefinition, courseDefinition?.name, loggedToAnalytics])

    // check if course exists
    if (isLoaded(allCourses) && !(topicId in allCourses)) {
        return <NotFound />;
    }

    return (
        <PageWrapper>
            <div className="topics-detail">
                <Link className="topics-detail__back-link" to="/courses">
                    {t('common.courses')}
                </Link>

                <div className="topics-detail__title">
                    <h2>{courseDefinition?.name[language]}</h2>
                    <button onClick={handleCourse} className="button-primary">
                        {course?.timeCompleted
                            ? t('common.startAgainBtn')
                            : course?.timeStarted
                            ? t('common.continueBtn')
                            : t('common.startNowBtn')}
                    </button>
                </div>

                <div className="topics-detail__tile" onClick={goToCourse}>
                    <div className="topics-detail__image">
                        <TopicsTile
                            topicId={topicId}
                            course={course}
                            isDetail
                        />
                        <hr />
                    </div>
                    <div className="topics-detail__summary">
                        <h4>{t('common.summary')}</h4>
                        <p>{courseDefinition?.description[language]}</p>
                    </div>
                </div>
                <hr />
                {relatedPodcasts && (
                    <ScrollableSectionWithBadge
                        title="Related Podcasts"
                        badge={relatedPodcasts.length}
                        noHr
                    >
                        {relatedPodcasts.map((podcast, i) => (
                            <PodcastTile
                                key={i}
                                podcast={myPodcasts?.[podcast]}
                                podcastId={podcast}
                                hideRelatedCourse
                            />
                        ))}
                    </ScrollableSectionWithBadge>
                )}
                <ActionPanel visible>
                    <button onClick={handleCourse} className="button-primary">
                        {course?.timeCompleted
                            ? t('common.startAgainBtn')
                            : course?.timeStarted
                            ? t('common.continueBtn')
                            : t('common.startNowBtn')}
                    </button>
                </ActionPanel>
            </div>
        </PageWrapper>
    );
};

export default TopicsDetail;
