import React, { useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
// import useGetCStyleName from "@hooks/useGetCStyleName";
import { useTranslation } from "react-i18next";

//redux
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

//components
import CStyleBackground from "@components/CStyleBackground/CStyleBackground";
import PageWrapper from "@components/PageWrapper";
import { getCStyle } from "@components/Profile";
import Graph from '@components/Graph/Graph';

//styles
import "./style.css";


const ResultsContext = React.createContext({ x: 0, y: 0 });
ResultsContext.displayName = "ResultsContext";

const Results = ({ children, value }) => {

    // const myCStyle = useGetCStyleName() || null;
    const myCStyle = '';

    const cStyleDefinition = useSelector(
        (state) =>
            state.app.mainCourse &&
            state.app.mainCourse.json &&
            JSON.parse(state.app.mainCourse.json)
    );

    const history = useHistory();
    const firestore = useFirestore();
    const { t } = useTranslation();

    const userId = useSelector((state) => state.firebase.auth.uid);
    const user = useSelector((state) => state.firestore.data?.users?.[userId]);

    // avoid values near 0 not to be over the line
    const x = (Math.abs(value.x) * 0.9 + 0.1) * Math.sign(value.x);
    const y = (Math.abs(value.y) * 0.75 + 0.25) * Math.sign(value.y);

    const left = `${((x + 1) / 2) * 100}%`;
    const top = `${(1 - (y + 1) / 2) * 100}%`;

    const goToCourse = useCallback(
        (id) => {
            firestore
                .update(
                    {
                        collection: `users/${userId}/userCourses`,
                        doc: `${id}`,
                    },
                    {
                        results: firestore.FieldValue.delete(),
                        timeCompleted: null,
                        timeStarted: null,
                    }
                )
                .then(() => {
                    history.push(`/course/${id}`);
                });
        },
        [history, firestore, userId]
    );

    return (
        <ResultsContext.Provider value={value}>
            <div className="c-style-results">
                <PageWrapper>
                    <div className="location-header">
                        <div>
                            <Link to="/">
                                {t("resultsPages.bottomButtons.dash")}
                            </Link>
                            <span className="profile-c-style-span">{t("resultsPages.cStyle")}</span>
                        </div>

                        <div className="retake-btn">
                            <button
                                className="button-primary button-primary--desktop  button-primary--remake"
                                onClick={() => goToCourse(user?.mainCourse, 'take_course', 'Finding your C-Style')}
                            >
                                {t("resultsPages.buttons")}
                            </button>
                        </div>
                    </div>

                    <div className="c-style-name-container">
                        <div className="result_quadrant">
                            <div className="result_quadrant__header page-wrapper--full-width">
                                <CStyleBackground />

                                <div className="result_quadrant__copy">
                                    <h6 className="smaller">
                                        {t("resultsPages.yourCStyle")}
                                    </h6>
                                    <h1>{t(`resultsPages.styles.${myCStyle}`)}</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className='static-text'>
                        {t("resultsPages.stylesIntro")}
                    </p>

                    <div className="main-zone-paragraph">
                        <h1>{t("resultsPages.yourCStyle")}</h1>
                        {getCStyle(cStyleDefinition, value)}
                    </div>

                    <Graph
                        // left={left}
                        // top={top}
                        users={[{ left: left, top: top }]}
                        leftX={t('graph.introvert')}
                        rightX={t('graph.extrovert')}
                        topY={t('graph.people')}
                        bottomY={t('graph.facts')}
                        showCStyleLabels={true}
                    />

                    <div>{children}</div>
                </PageWrapper>
            </div>
        </ResultsContext.Provider>
    );
};

export default Results;

const Context = ResultsContext.Consumer;

const IfResultInRange = ({ range, noRange, children }) => {
    return (
        <Context>
            {(value) => {
                let [min, max] = range;
                if (max.x === 1) {
                    max.x = 1.01;
                }
                if (max.y === 1) {
                    max.y = 1.01;
                }
                if (
                    noRange ||
                    (value.x >= min.x &&
                        value.y >= min.y &&
                        value.x < max.x &&
                        value.y < max.y)
                ) {
                    return children;
                } else {
                    return null;
                }
            }}
        </Context>
    );
};

export { IfResultInRange };

export { ResultsContext };
