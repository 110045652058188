import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useTakeCourse from '@hooks/useTakeCourse';
import { useTranslation } from 'react-i18next';

import './CoursesTable.css';

//utils
import { analyticsLogEvent } from '@components/Analytics';
import { v4 as uuid } from 'uuid';

const CoursesTable = ({ showDiagnostics, showOnlyMain, showCourses, resultsText, cStyleName, showScience, showLiteCourses }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const language = i18n.language;
  const myCourses = useSelector(state => state.firestore.data?.myCourses || {});
  const allCourses = useSelector(state => state.firestore.data?.courses || {});
  const [groupedCourses, setGroupedCourses] = useState({
    science: [],
    diagnostics: [],
    courses: [],
    liteCourses: []
  });

  const groupCourses = useCallback(() => {
    const gCourses = {
      science: [],
      diagnostics: [],
      courses: [],
      liteCourses: []
    }

    Object.entries(allCourses).forEach(([courseId, course]) => {
      if (Object.keys(myCourses).includes(courseId)) {
        if (course.category === 'science') {
          gCourses.science.push([courseId, course]);
        } else if (course.category === 'diagnostics') {
          gCourses.diagnostics.push([courseId, course]);
        } else {
          gCourses.courses.push([courseId, course]);
        }
        if (course.allowLite) {
          gCourses.liteCourses.push([courseId, course]);
        }
      }
    });

    // Sort courses by order
    Object.values(gCourses).forEach(arr => {
      arr.sort((a, b) => a[1].order - b[1].order)
    });
    console.log(gCourses);

    setGroupedCourses(gCourses);
  }, [allCourses])

  useEffect(() => {
    if (groupedCourses.courses.length === 0) {
      groupCourses();
    }
  }, [allCourses, myCourses, groupedCourses.courses.length, groupCourses])

  const { takeCourse } = useTakeCourse();

  const continueCourse = useCallback((key) => {
    analyticsLogEvent('continue_course', { course: allCourses[key].name['en'] })
    history.push(`/course/${key}`);
  }, [history, allCourses]);

  const coursesStatus = {
    en: {
      go: "Go",
      continue: "Continue",
      retake: "Retake"
    },
    es: {
      go: "Empezar",
      continue: "Seguir",
      retake: "Reiniciar"
    },
    pt: {
      go: "Começar",
      continue: "Continuar",
      retake: "Reiniciar"
    }
  }

  const handleCourseStatus = (key) => {
    if (!myCourses[key]?.isTaken && !myCourses[key]?.timeStarted) {
      return coursesStatus[language].go;
    } else if (myCourses[key]?.timeStarted && !myCourses[key]?.timeCompleted) {
      return coursesStatus[language].continue;
    } else {
      return coursesStatus[language].retake;
    }
  }

  return (
    <div>
      {
        showScience && groupedCourses.science && groupedCourses.science.map((course, index) => {
          return (
            <div key={uuid()} className={`course-row ${index === groupedCourses.science.length - 1 ? 'no-border-bottom' : null}`}>

              <div className='left-column'>
                <img src={course[1].image} alt={course[1].name[language]} />
              </div>

              <hr size="50" />

              <div className='middle-column c-style-row'>
                <p>{course[1].name[language]}</p>
              </div>

              <hr size="50" />

              <div className='right-column'>
                <button
                  className='goBtn'
                  onClick={() => {
                    if (myCourses[course[0]]?.timeStarted && !myCourses[course[0]]?.timeCompleted) {
                      continueCourse(course[0]);
                    } else {
                      takeCourse(course[0], handleCourseStatus(course[0]), course[1].name[language]);
                    }
                  }}
                >
                  {course[1].name[language] === 'Prepare for Q&A' ? 'View' : handleCourseStatus(course[0])}
                </button>
              </div>
            </div>
          )
        })
      }

      {
        showDiagnostics && !showOnlyMain && groupedCourses.diagnostics && groupedCourses.diagnostics.map((course, index) => {
          return (
            <div key={uuid()} className={`course-row ${index === groupedCourses.diagnostics.length - 1 ? 'no-border-bottom' : null}`}>

              <div className='left-column'>
                <img src={course[1].image} alt={course[1].name[language]} />
              </div>

              <hr size="50" />

              <div className='middle-column c-style-row'>
                <p>{course[1].name[language]}</p>
                <span onClick={() => myCourses[course[0]]?.isTaken ? history.push(`${course[1].appLink}`) : null}>
                  {
                    myCourses[course[0]]?.isTaken && course[1].isMain ? cStyleName
                      : myCourses[course[0]]?.isTaken && !course[1].isMain ? resultsText
                        : null
                  }
                </span>
              </div>

              <hr size="50" />

              <div className='right-column'>
                <button
                  className='goBtn'
                  onClick={() => {
                    if (myCourses[course[0]]?.timeStarted && !myCourses[course[0]]?.timeCompleted) {
                      continueCourse(course[0]);
                    } else {
                      takeCourse(course[0], handleCourseStatus(course[0]), course[1].name[language]);
                    }
                  }}
                >
                  {handleCourseStatus(course[0])}
                </button>
              </div>
            </div>
          )
        })
      }

      {
        showDiagnostics && showOnlyMain && groupedCourses.diagnostics && groupedCourses.diagnostics.map((course, index) => {
          if (course[1].isMain) {
            return (
              <div key={uuid()} className={`course-row ${index === groupedCourses.diagnostics.length - 1 ? 'no-border-bottom' : null}`}>

                <div className='left-column'>
                  <img src={course[1].image} alt={course[1].name[language]} />
                </div>

                <hr size="50" />

                <div className='middle-column c-style-row'>
                  <p>{course[1].name[language]}</p>
                  <span onClick={() => myCourses[course[0]]?.isTaken ? history.push(`${course[1].appLink}`) : null}>
                    {
                      myCourses[course[0]]?.isTaken && course[1].isMain ? cStyleName
                        : myCourses[course[0]]?.isTaken && !course[1].isMain ? resultsText
                          : null
                    }
                  </span>
                </div>

                <hr size="50" />

                <div className='right-column'>
                  <button
                    className='goBtn'
                    onClick={() => {
                      if (myCourses[course[0]]?.timeStarted && !myCourses[course[0]]?.timeCompleted) {
                        continueCourse(course[0]);
                      } else {
                        takeCourse(course[0], handleCourseStatus(course[0]), course[1].name[language]);
                      }
                    }}
                  >
                    {handleCourseStatus(course[0])}
                  </button>
                </div>
              </div>
            )
          }
          return null;
        })
      }

      {
        showCourses && groupedCourses.courses && groupedCourses.courses.map((course, index) => {
          return (
            <div key={uuid()} className={`course-row ${index === groupedCourses.courses.length - 1 ? 'no-border-bottom' : null}`}>

              <div className='left-column'>
                <img src={course[1].image} alt={course[1].name[language]} />
              </div>

              <hr size="50" />

              <div className='middle-column'>
                <p>{course[1].name[language]}</p>
              </div>

              <hr size="50" />

              <div className='right-column'>
                <button
                  className='goBtn'
                  onClick={() => {
                    if (myCourses[course[0]]?.timeStarted && !myCourses[course[0]]?.timeCompleted) {
                      continueCourse(course[0]);
                    } else {
                      takeCourse(course[0], handleCourseStatus(course[0]), course[1].name[language]);
                    }
                  }}
                >
                  {handleCourseStatus(course[0])}
                </button>
              </div>
            </div>
          )
        })
      }

      {
        showLiteCourses && groupedCourses.liteCourses && groupedCourses.liteCourses.map((course, index) => {
          return (
            <div key={uuid()} className={`course-row ${index === groupedCourses.diagnostics.length - 1 ? 'no-border-bottom' : null}`}>

              <div className='left-column'>
                <img src={course[1].image} alt={course[1].name[language]} />
              </div>

              <hr size="50" />

              <div className='middle-column c-style-row'>
                <p>{course[1].name[language]}</p>
                <span onClick={() => myCourses[course[0]]?.isTaken ? history.push(`${course[1].appLink}`) : null}>
                  {
                    myCourses[course[0]]?.isTaken && course[1].isMain ? cStyleName
                      : myCourses[course[0]]?.isTaken && !course[1].isMain ? resultsText
                        : null
                  }
                </span>
              </div>

              <hr size="50" />

              <div className='right-column'>
                <button
                  className='goBtn'
                  onClick={() => {
                    if (myCourses[course[0]]?.timeStarted && !myCourses[course[0]]?.timeCompleted) {
                      continueCourse(course[0]);
                    } else {
                      takeCourse(course[0], handleCourseStatus(course[0]), course[1].name[language]);
                    }
                  }}
                >
                  {handleCourseStatus(course[0])}
                </button>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default CoursesTable