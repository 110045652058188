import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import RadioButton from '@components/RadioButton/RadioButton';
import { updateConferenceResult } from '@utils/appUtils';
import './JobOptions.css';

const JobOptions = ({ children }) => {
  const [selectedChild, setSelectedChild] = useState();
  const uid = useSelector(state => state.firebase.auth?.uid);
  const conference = useSelector(state => state.firestore?.data?.users?.[uid]?.conference);

  const handleChildClick = (index, name) => {
    setSelectedChild(index);
    updateConferenceResult(uid, conference, {
      job: name
    })
  }

  const renderChildren = () => {
    const modifiedChildren = React.Children.toArray(children).map((child, index) => {
      return React.cloneElement(child,
        {
          ...child.props,
          index,
          handleClick: handleChildClick,
          selected: index === selectedChild
        }
      )
    })

    return modifiedChildren;
  }

  return (
    <div className='jobs-container'>
      {renderChildren()}
    </div>
  )
}

JobOptions.Job = React.memo(({ index, name, handleClick, selected }) => {
  return (
    <div className='job-container'>
      <RadioButton
        index={index}
        name={name}
        handleClick={handleClick}
        selected={selected}
      />
    </div>
  )
});

export default JobOptions