import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { removeEmptySpacesFromString } from "@utils/appUtils";
import firebase from "firebase/app";

const FirebaseAnalytics = () => {
    let location = useLocation();
    useEffect(() => {
        const analytics = firebase && firebase.analytics;
        if (typeof analytics === "function") {
            const page_path = location.pathname + location.search;
            analytics().setCurrentScreen(page_path);
            analytics().logEvent("page_view", { page_path });
        }
    }, [location]);
    return null;
};

export default FirebaseAnalytics;

export const analyticsLogEvent = (event, eventParams) => {
    if (!event) {
        return;
    }

    const safeEvent = removeEmptySpacesFromString(event.slice(0, 40));
    const analytics = firebase && firebase.analytics;

    if (typeof analytics === 'function') {
        console.log('loging event: ' + safeEvent);
        console.log('eventParams: ', eventParams);

        analytics().logEvent(
            removeEmptySpacesFromString(event.slice(0, 40)),
            eventParams
        )
    }
}

export const EVENTS = {
    user_sign_in: 'user_sign_in',
    user_sign_in_ev: 'user_sign_ev_',
    start_course: 'start_course_',
    ev_user_start_course: '_start_course_',
    ev_user_finish_course: '_finish_course_',
    watch_video: 'watch_video_',
    ev_watch_video: '_watch_video_',
    loading_time: '_loading_'
}