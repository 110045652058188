import React from 'react';
import { useDispatch } from 'react-redux';
import { IoPlay } from 'react-icons/io5';
import { playVideo } from '@utils/appUtils';
import './PersonalisedVideo.css';

const PersonalisedVideo = ({ bgClass, src, name, playedFrom }) => {
  const dispatch = useDispatch();

  return (
    <div className={`personalised-video ${bgClass}-preview`}
      onClick={() => playVideo(dispatch, src, name, playedFrom)}
    >
      <IoPlay />
    </div>
  )
}

export default PersonalisedVideo
