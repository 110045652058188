import React from "react";
import "./style.css";
import PageWrapper from "@components/PageWrapper";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import AppSlice from "@store/app";
import { useTranslation } from "react-i18next";
import Flags from "@components/Flags/Flags";

const Menu = (props) => {
    const { onClose } = props;
    const userId = useSelector(state => state.firebase?.auth?.uid);

    const isStylePending = useSelector((state) => state.app.isStylePending);
    const allowMultiLingual = useSelector(state => state?.app.allowMultiLingual);
    const isLiteUser = useSelector(state => state?.firestore?.data?.users?.[userId]?.liteUser);

    const dispatch = useDispatch();

    const firebase = useFirebase();
    const { t } = useTranslation();

    const doLogout = () => {
        // clean up stored email after conscious logout
        window.localStorage.setItem("emailForSignIn", "");
        firebase.logout();
        onClose();
    };

    const onHandleLink = (e, link) => {
        switch (link) {
            case "Courses":
            case "Podcasts":
                if (isStylePending) {
                    e.preventDefault();
                    dispatch(
                        AppSlice.actions.setModal({
                            visible: true,
                            content: { type: "styleRequired" },
                        })
                    );
                } else {
                    onClose();
                }
                break;
            case "Privacy & Cookies":
                e.preventDefault();
                dispatch(
                    AppSlice.actions.setModal({
                        visible: true,
                        content: { type: "privacyPolicy" },
                    })
                );
                break;
            case "Terms & Conditions":
                e.preventDefault();
                dispatch(
                    AppSlice.actions.setModal({
                        visible: true,
                        content: { type: "termsAndConditions" },
                    })
                );
                break;
            default:
                onClose();
        }
    };

    const editProfile = () => {
        dispatch(
            AppSlice.actions.setModal({
                visible: true,
                content: { type: "editProfile" },
            })
        );
    };

    const linksMain = [
        {
            displayText: t("navMenu.dashboard"),
            text: "Your dashboard",
            slug: "/",
        },
        {
            displayText: t("navMenu.courses"),
            text: "Courses",
            slug: "/courses",
        },
        // {
        //     displayText: t("navMenu.podcasts"),
        //     text: "Podcasts",
        //     slug: "/podcasts",
        // },
        {
            displayText: t("navMenu.certificates"),
            text: "Your Certificates",
            slug: "/certificates",
        },
    ];

    const linksSecondary = [
        {
            displayText: t("navMenu.help"),
            text: "Help",
            slug: "/help",
        },
        {
            displayText: t("navMenu.about"),
            text: "About Rippl",
            slug: "/about",
        },
        {
            displayText: t("navMenu.privacyAndCookies"),
            text: "Privacy & Cookies",
            slug: "/privacy-and-cookies",
        },
        {
            displayText: t("navMenu.termsAndConditions"),
            text: "Terms & Conditions",
            slug: "/terms-and-conditions",
        },
    ];

    return (
        <nav>
            <PageWrapper>
                <ul className="main-list">
                    {linksMain.map((link, i) => (
                        <li key={i} className="main">
                            <Link
                                to={link.slug}
                                onClick={(e) => onHandleLink(e, link.text)}
                            >
                                {link.displayText}
                            </Link>
                        </li>
                    ))}
                </ul>

                <ul className="second-list">
                    {linksSecondary.map((link, i) => (
                        <li key={i}>
                            <Link
                                to={link.slug}
                                onClick={(e) => onHandleLink(e, link.text)}
                            >
                                {link.displayText}
                            </Link>
                        </li>
                    ))}
                    <li onClick={editProfile}>{t("navMenu.editProfile")}</li>
                    <hr />
                    <div className={`${allowMultiLingual ? 'sign-out-container-mobile' : 'sign-out-container-desktop'}`}>
                        <div className="btn-container">
                            <button onClick={doLogout}>{t("navMenu.signOut")}</button>
                        </div>

                        {allowMultiLingual &&
                            <div className="flags-box-mobile">
                                <Flags />
                            </div>
                        }
                    </div>
                </ul>

                {
                    allowMultiLingual &&
                    <ul className="third-list">
                        <div>
                            <div className="sign-out-container-desktop">
                                <div className="flags-box-desktop">
                                    <Flags />
                                </div>
                                <div className="btn-container">
                                    <button onClick={doLogout}>{t("navMenu.signOut")}</button>
                                </div>
                            </div>
                        </div>
                    </ul>}
            </PageWrapper>
        </nav>
    );
};

export default Menu;
