import React, { useState, useEffect, useRef } from 'react';
import './SearchSelect.css';

function SearchSelect({ options, name, onChange = () => null }) {

  const [searchData, setSearchData] = useState('');
  const [selectOptions, setSelectOptions] = useState(options);
  const [selectOptionsCopy, setSelectOptionsCopy] = useState(options)
  const [selectedOption, setSelectedOption] = useState();
  const [inputFocus, setInputFocus] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null);

  const inputContainerRef = useRef(null);

  function handleSelectedOption(option) {
    setSelectedOption(option);
    onChange({ ...option, name: name });
  }

  function handleInputChange(e) {
    setSearchData(e.target.value);
    handleSelectedOption();
  }

  function hideInputSelectFields() {
    setInputFocus(false);
  }

  function showInputSelectFields() {
    setInputFocus(true);
    resetSelectOptions();
    setHoverIndex(null);
  }

  function handleSelectClick(option) {
    setSearchData(option.text);
    handleSelectedOption(option);
    hideInputSelectFields();
  }

  function handleInputKeyDown(e) {
    if (['Enter', 'NumpadEnter', 'Tab'].includes(e.code)) {
      if (searchData && selectOptions[0]) {
        setSearchData(selectOptions[0].text);
        handleSelectedOption(selectOptions[0]);
      }
      hideInputSelectFields();
    } else if (!inputFocus) {
      showInputSelectFields();
    }
  }

  function resetSelectOptions() {
    setSelectOptions(options);
  }

  useEffect(() => {
    if (searchData) {
      const newSelectOptions = selectOptionsCopy.filter(option => containsInExactOrder(option.text, searchData));
      if (newSelectOptions[0]) {
        setHoverIndex(0);
      }
      setSelectOptions(newSelectOptions);
    } else {
      resetSelectOptions();
      setHoverIndex(null);
    }
  }, [searchData]);

  useEffect(() => {
    // Hide input data if user clicks outside
    function handleClickOutside(event) {
      if (inputContainerRef.current && !inputContainerRef.current.contains(event.target)) {
        if (!selectedOption) {
          setSearchData('');
        }
        hideInputSelectFields();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputContainerRef, selectedOption]);

  function containsInExactOrder(str, orderStr) {
    str = str.toLowerCase();
    orderStr = orderStr.toLowerCase();

    let charFoundAtIndex = 0;

    for (let char of orderStr) {
      let foundChar = false;
      for (let i = charFoundAtIndex; i < str.length; i++) {
        if (char === str[i]) {
          foundChar = true;
          charFoundAtIndex = i;
          break;
        }
      }
      if (!foundChar) {
        return false;
      }
    }
    return true;
  }

  return (
    <div ref={inputContainerRef} className='search-select-container'>
      <input
        type="text"
        value={searchData}
        onChange={handleInputChange}
        onFocus={showInputSelectFields}
        onKeyDown={handleInputKeyDown}
      />
      {inputFocus &&
        <div className={`select-fields`}>
          {selectOptions.map((option, index) => (
            <h1
              key={option.text}
              onClick={() => handleSelectClick(option)}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
              className={`${index === hoverIndex && 'h1-hover'}`}
            >
              {option.text}
            </h1>))}
        </div>
      }
    </div>
  )
}

export default SearchSelect