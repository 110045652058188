import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import './PieChart.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data, labels, backgroundColors, borderColors, labelsPosition, chartTitle }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: '# of Votes',
        data: data,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 3,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: labelsPosition,
      },
      title: {
        display: true,
        text: chartTitle,
      },
    },
  };

  if (data.length < 1) return <div className="no-data-label"><label>No data yet...</label></div>
  return <Pie data={chartData} options={options} />
}

export default PieChart;
