import firebase from "firebase";

export const getAllowSeeResultsState = async (conferenceId) => {
  const confDocSnap = await firebase.firestore()
    .collection('conference')
    .doc(conferenceId)
    .get();

  const currentState = confDocSnap.data()?.allowSeeResults;

  return currentState;
}

export const toggleAllowSeeResults = async (conferenceId) => {
  console.log(conferenceId);

  const currentState = await getAllowSeeResultsState(conferenceId);
  await firebase.firestore().collection('conference').doc(conferenceId).update({
    allowSeeResults: !currentState
  })
}