import React from 'react';
import "./CardsContainer.css";

function CardsContainer({ title, subtitle, children, conclusion }) {
  return (
    <div className="cards-container">
      <div className="header">
        {title && <h1>{title}</h1>}
        {subtitle && <h2>{subtitle}</h2>}
      </div>
      <div className="cards-section">
        {children}
      </div>
      {conclusion &&
        <div className="conclusion">
          <h1>{conclusion}</h1>
        </div>
      }
    </div>
  )
}

export default CardsContainer