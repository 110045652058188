import React, { useEffect } from 'react'
import './List.css';

function List({ question, title, subtitle, listItems }) {
  useEffect(() => {
    // Populate survey data if there's none
    if (!question.data.data[question.title]) {
      const itms = listItems.map(item => ({ text: item.text, checked: false, dependancies: item.dependancies }));
      question.data.data = {
        [question.title]: itms
      }
    }
  }, [])

  function toggleThick(index) {
    const checkedItemsCopy = [...question.data.data[question.title]];
    checkedItemsCopy[index].checked = !checkedItemsCopy[index].checked;

    // Untick dependancies if there's any
    if (checkedItemsCopy[index].dependancies) {
      checkedItemsCopy[index].dependancies.forEach(dependancy => {
        checkedItemsCopy[dependancy].checked = false;
      })
    }

    question.data.data = {
      [question.title]: checkedItemsCopy
    }
  }

  const ListItems = question.data.data[question.title] ? question.data.data[question.title].map((item, index) => {
    console.log(item);

    return <ListItem
      key={item.text}
      text={item.text}
      checked={item.checked}
      onClick={() => toggleThick(index)}
    />
  }) : [];

  return (
    <div className='list'>
      <div>
        <div className='list__header'>
          <h1 className='list__header_title'>{title}</h1>
          <p className='list__header_subtitle'>{subtitle}</p>
        </div>
        <div className='list__items'>
          {ListItems}
        </div>
      </div>
    </div>
  )
}

function ListItem({ text, onClick, checked }) {
  return (
    <div className='checklist-item' onClick={onClick}>
      <div className='checklist-outer-square'>
        {checked && <div className='checklist-inner-square'></div>}
      </div>
      <span>{text}</span>
    </div>
  )
}

export default List