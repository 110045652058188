import React, { useEffect, useState } from 'react';

// Hooks
import { useHistory } from "react-router-dom";
import { useHideHeader } from '@hooks/useHideHeader';

// Components
import SearchSelect from '@components/SearchSelect/SearchSelect';
import { PrimaryButton } from '@components/Buttons/Buttons';

// Utils
import { updateConferenceResult } from '@utils/appUtils';

// Style
import './SimpleRevolutionSurvey.css';

function SimpleRevolutionSurvey({ uid, conference, userNumber, showedUserNumber, conferenceJobs, ages, genders }) {
  useHideHeader();

  const history = useHistory();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [surveyState, setSurveyState] = useState({
    job: '',
    age: '',
    gender: ''
  })

  const conferenceJobsTitles = Object.keys(conferenceJobs || {})
    .sort((a, b) => (
      a > b ? 1 : -1
    ));

  function handleSelectChange(e) {
    setSurveyState(surveyState => ({
      ...surveyState,
      [e.name]: e.value
    }))
  }

  function handleSubmitForm() {
    updateConferenceResult(uid, conference, surveyState);
    history.push('/c-style/false');
  }

  useEffect(() => {
    let foundEmpty = false;
    for (let value of Object.values(surveyState)) {
      if (!value) {
        foundEmpty = true;
      }
    }
    if (foundEmpty) {
      setSubmitButtonDisabled(true);
    } else {
      setSubmitButtonDisabled(false);
    }
  }, [surveyState])

  return (
    <div className='inter-screen-container'>
      <div className='sr-survey-container'>
        <p>
          Thanks for completing the survey.
        </p>
        <p>
          You'll now be directed to [place] where you'll [benefit of place
          e.g. be able to get communication tips and see how you compare to
          other professionals OR be able to see how you compare to other
          professionals and found out more about Simple Revolution.]
        </p>
        <p>
          As always, all of your data will remain anonymised and solely used
          for the purpose of improving other people's C-Styles.
        </p>

        <div style={{ marginTop: 30 }}>
          <p>Job:</p>
          <SearchSelect
            tabIndex={-1}
            options={conferenceJobsTitles.map(jobTitle => {
              return { text: jobTitle, value: jobTitle }
            })}
            onChange={handleSelectChange}
            name={'job'}
          />
        </div>

        <div>
          <p>Age:</p>
          <SearchSelect
            tabIndex={-1}
            options={ages.map(age => {
              return { text: age, value: age }
            })}
            onChange={handleSelectChange}
            name={'age'}
          />
        </div>

        <div>
          <p>Gender: </p>
          <SearchSelect
            tabIndex={-1}
            options={genders.map(gender => {
              return { text: gender, value: gender }
            })}
            onChange={handleSelectChange}
            name={'gender'}
          />
        </div>

        <PrimaryButton
          onClick={handleSubmitForm}
          disabled={submitButtonDisabled}
          additionalClasses={'submit-btn'}
        >
          See your results
        </PrimaryButton>
      </div>

    </div>
  )
}

export default SimpleRevolutionSurvey