import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import firebase from 'firebase';

import PieChartWithCustomLegend from '@components/Charts/PieChartWithCustomLegend/PieChartWithCustomLegend';
import PieChartsContainer from '@components/Charts/PieChartsContainer/PieChartsContainer';
import GlobalMapPannel from '@components/GlobalMapPannel/GlobalMapPannel';
import CustomVideoPlayer from '@components/Video/CustomVideoPlayer';
import PageWrapper from '@components/PageWrapper';
import { MiniDot } from '@components/Graph/Dots';
import Graph from '@components/Graph/Graph';

import { useDispatch } from 'react-redux';
import AppSlice from "@store/app";

import './GlobalMapPage.css';
import { useParams } from 'react-router';
import { useListenToConference } from '@hooks/useListenToConference';
import { useGetConferences } from '@hooks/useGetConferences';
import { useShowHeader } from '@hooks/useShowHeader';
import { updateConferenceResult } from '@utils/appUtils';

const GlobalMapPage = () => {
  const { conferenceId, showHeader, showPannel, showCharts, ripplUserId } = useParams();
  useShowHeader(showHeader);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [conferenceToListen, setConferenceToListen] = useState();

  const {
    conferenceJobs,
    setConferenceJobs,
    conferenceResults,
    allJobsGrey,
    setAllJobsGrey
  } = useListenToConference(conferenceId || conferenceToListen, ripplUserId);
  const allConferences = useGetConferences();


  const [jobsPerQuardantData, setJobsPerQuardantData] = useState();
  const [allUsersOff, setAllUsersOff] = useState(false);
  const [autoToggleAllJobsGreyOn, setAutoToggleAllJobsGreyOn] = useState(false);
  const [autoToggleAllJobsGreyOff, setAutoToggleAllJobsGreyOff] = useState(false);
  const [stylesResults, setStylesResults] = useState();

  console.log('GlobalMapPage renders');

  const toggleAllUsersOff = () => {
    setAllUsersOff(!allUsersOff);
  }

  const toggleAllJobsGrey = (state) => {
    setAllJobsGrey(state)
    setAutoToggleAllJobsGreyOn(false)
    setAutoToggleAllJobsGreyOff(false);
  }

  const checkAllSameColorState = () => {
    const jobsList = conferenceJobs ? Object.values(conferenceJobs) : [];
    const foundJobsWithColorStateOff = jobsList.find(job => job.showDotColor === false);
    const foundJobsWithColorStateOn = jobsList.find(job => job.showDotColor === true);

    if (!!foundJobsWithColorStateOff !== !!foundJobsWithColorStateOn) {
      if (!!foundJobsWithColorStateOff) {
        setAutoToggleAllJobsGreyOn(true);
      } else {
        setAutoToggleAllJobsGreyOff(true);
      }
    }
  }

  const handleShowJobsColor = (jobTitle) => {
    const jobsList = conferenceJobs ? Object.keys(conferenceJobs).map(key => key) : {};
    const jobsWithCollorState = { ...conferenceJobs } || {};

    if (jobTitle === 'all') {
      Object.keys(jobsWithCollorState).forEach(key => {
        jobsWithCollorState[key].showDotColor = !allJobsGrey;
      })
      setConferenceJobs(jobsWithCollorState)
    } else {
      const foundJobAtIndex = jobsList.findIndex(job => job === jobTitle)
      if (foundJobAtIndex !== -1) {
        jobsWithCollorState[jobsList[foundJobAtIndex]].showDotColor = !jobsWithCollorState[jobsList[foundJobAtIndex]].showDotColor;
        checkAllSameColorState();
        setConferenceJobs(jobsWithCollorState);
      }
    }
  }

  useEffect(() => {
    handleShowJobsColor('all');
  }, [allJobsGrey]);

  useEffect(() => {
    if (conferenceResults) {
      let stylesResultsObj = {
        total: 0,
        connecting: {
          total: 0
        },
        examining: {
          total: 0
        },
        influencing: {
          total: 0
        },
        delivering: {
          total: 0
        }
      }
      let jobsPerQuadrant = {
        connecting: {
          total: 0,
          jobs: {}
        },
        examining: {
          total: 0,
          jobs: {}
        },
        influencing: {
          total: 0,
          jobs: {}
        },
        delivering: {
          total: 0,
          jobs: {}
        }
      }

      conferenceResults.forEach(result => {
        // Bar chart
        stylesResultsObj.total++;
        stylesResultsObj[result.cStyleName].total++

        // Pie charts
        if (jobsPerQuadrant[result.cStyleName].jobs[result.job]) {
          jobsPerQuadrant[result.cStyleName].jobs[result.job]++;
          jobsPerQuadrant[result.cStyleName].total++;
        } else if (result.job) {
          jobsPerQuadrant[result.cStyleName].jobs[result.job] = 1;
          jobsPerQuadrant[result.cStyleName].total++;
        }
      })

      setStylesResults(stylesResultsObj)
      setJobsPerQuardantData(jobsPerQuadrant);
    }
  }, [conferenceResults]);

  // Handle navbar menu visible if user is signed in
  useEffect(() => {
    const user = firebase.auth().currentUser;
    if (showHeader === 'false') {
      dispatch(AppSlice.actions.setHeader({ visible: false, showHamburger: false }));
    } else if (user) {
      dispatch(AppSlice.actions.setHeader({ visible: true, showHamburger: true }));
    } else {
      dispatch(AppSlice.actions.setHeader({ visible: true, showHamburger: false }));
    }
  }, [showHeader])

  const setConfToListen = useCallback((e) => {
    setConferenceToListen(e.target.value)
  }, [])

  return (
    <PageWrapper>
      <div className='global-map-container'>
        {!conferenceId &&
          <>
            <label htmlFor='conf-select'>Select event </label>
            <select name='conf' id='conf-select' value={conferenceToListen} onChange={setConfToListen}>
              <option value={''}>{''}</option>
              {
                allConferences.map(conf => {
                  const key = uuid();
                  return <option key={key} value={conf.ref.id}>{conf.name}</option>
                })
              }
            </select>
          </>
        }

        {
          conferenceResults && jobsPerQuardantData &&
          <div className='gmap-section'>
            <h1>What's your style?</h1>
            <div id="dot-link1">
              <Graph
                users={!allUsersOff ? conferenceResults : []}
                conferenceJobs={conferenceJobs}
                stylesResults={showHeader !== 'false' ? stylesResults : null}
                leftX={t('graph.introvert')}
                rightX={t('graph.extrovert')}
                topY={t('graph.people')}
                bottomY={t('graph.facts')}
                showCStyleLabels={true}
                dotComponent={MiniDot}
              />
            </div>
          </div>
        }

        {showPannel !== 'false' && conferenceResults && conferenceJobs &&
          <GlobalMapPannel
            conferenceId={conferenceToListen}
            conferenceJobs={conferenceJobs}
            toggleAllJobsGrey={toggleAllJobsGrey}
            toggleAllUsersOff={toggleAllUsersOff}
            handleShowJobsColor={handleShowJobsColor}
            autoToggleAllJobsGreyOn={autoToggleAllJobsGreyOn}
            autoToggleAllJobsGreyOff={autoToggleAllJobsGreyOff}
          />
        }

        {showCharts !== 'false' && conferenceResults && conferenceJobs && jobsPerQuardantData &&
          <div className='gmap-section'>
            <h1>What do you do?</h1>
            <PieChartsContainer>
              <PieChartWithCustomLegend
                label='Connecting'
                data={jobsPerQuardantData.connecting}
                conferenceJobs={conferenceJobs}
              />
              <PieChartWithCustomLegend
                label="Influencing"
                data={jobsPerQuardantData.influencing}
                conferenceJobs={conferenceJobs}
              />
              <PieChartWithCustomLegend
                label="Examining"
                data={jobsPerQuardantData.examining}
                conferenceJobs={conferenceJobs}
              />
              <PieChartWithCustomLegend
                label="Delivering"
                data={jobsPerQuardantData.delivering}
                conferenceJobs={conferenceJobs}
              />
            </PieChartsContainer>
          </div>
        }
      </div>

      {ripplUserId &&
        <button
          className='pulse-btn'
          onClick={() => updateConferenceResult(ripplUserId, conferenceId, { pulse: true })}
        >
          Highlight my result
        </button>
      }

      <CustomVideoPlayer />
    </PageWrapper >
  )
}

export default GlobalMapPage