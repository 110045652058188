import { useState, useEffect } from 'react';
import firebase from 'firebase';

export function useGetConferences() {
  const [allConferences, setAllConferences] = useState([]);

  useEffect(() => {
    (async () => {
      const confQuerySnap = await firebase
        .firestore()
        .collection('conference')
        .get();
      const confs = confQuerySnap.docs.map(conf => {
        const confName = conf.data().name;
        const confRef = conf.ref;
        return { name: confName, ref: confRef }
      })
      setAllConferences(confs);
    })()
  }, [])

  return allConferences;
}