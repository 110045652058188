import { useEffect } from "react";
import { useDispatch } from "react-redux";
import AppSlice from "@store/app";

export function useHideHeader() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AppSlice.actions.setHeader(
      { visible: false, showHamburger: false }
    ));
  }, []);

  return null;
}