import classnames from "classnames";

export const PrimaryButton = ({ children, onClick, additionalClasses, disabled, ...rest }) => {
  return (
    <button
      className={classnames(
        'button-primary',
        `${additionalClasses ? additionalClasses : ''}`,
        { 'button-primary--disabled': disabled }
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  )
}

export const SecondaryButton = ({ children, onClick, additionalClasses, disabled, ...rest }) => {
  return (
    <button
      className={classnames(
        'button-secondary',
        `${additionalClasses ? additionalClasses : ''}`,
        { 'button-secondary--disabled': disabled }
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  )
}