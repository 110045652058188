import React, { useState, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Components
import SlideToggle from '@components/SlideToggle/SlideToggle';
import JobLegend from '@components/JobLegend/JobLegend';

// Controllers
import { toggleAllowSeeResults, getAllowSeeResultsState } from '@utils/actions';

// Styles
import './GlobalMapPannel.css';

const GlobalMapPannel = ({
  conferenceJobs,
  conferenceId,
  toggleAllJobsGrey,
  toggleAllUsersOff,
  handleShowJobsColor,
  autoToggleAllJobsGreyOn,
  autoToggleAllJobsGreyOff
}) => {
  const [currentAllowSeeResultsState, setCurrentAllowSeeResultsState] = useState(false);

  useEffect(() => {
    (async () => {
      const currState = await getAllowSeeResultsState(conferenceId);
      setCurrentAllowSeeResultsState(currState);
    })()
  }, []);

  const jobsLegendElements = Object.keys(conferenceJobs).map((jobTitle, index) => {
    return <JobLegend
      key={jobTitle + index}
      text={jobTitle}
      color={conferenceJobs[jobTitle].showDotColor ? conferenceJobs[jobTitle].backgroundColor : 'rgba(189, 184, 168, .8'}
      handleClick={() => handleShowJobsColor(jobTitle)}
    />
  })

  return (
    <div className="pannel-container">
      <div className="pannel-container__jobs">
        {jobsLegendElements}
      </div>

      <div className="pannel-controls-container">
        <SlideToggle
          text="All off"
          defaultStateOn={false}
          handleToggle={toggleAllUsersOff}
        />

        <SlideToggle
          text="All grey"
          defaultStateOn={true}
          handleToggle={toggleAllJobsGrey}
          autoToggleOn={autoToggleAllJobsGreyOn}
          autoToggleOff={autoToggleAllJobsGreyOff}
        />

        <SlideToggle
          text="Make results visible"
          defaultStateOn={currentAllowSeeResultsState}
          handleToggle={() => toggleAllowSeeResults(conferenceId)}
        />
      </div>

    </div>
  )
}

export default GlobalMapPannel