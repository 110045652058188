import React, { useState, useEffect } from 'react'
import firebase from 'firebase'
import PageWrapper from '@components/PageWrapper';
import JsxParser from 'react-jsx-parser';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useTakeCourse from '@hooks/useTakeCourse';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useTranslation } from 'react-i18next';
import PageSpinner from '@components/PageSpinner/PageSpinner';

//components
import PWCGraph from '@components/PWCGraph/PWCGraph';

//styles
import './PWCResults.css';

const PWCResults = () => {
  const firestore = firebase.firestore();
  const userId = useSelector(state => state.firebase.auth.uid);
  const userLastPwcResult = useSelector(state => state.app.lastCompletedPWC);
  const [userPWCCourseHTML, setUserPWCCourseHTML] = useState('');
  const [showSpinner, setShowSpinner] = useState(true);
  const [values, setValues] = useState({});
  const { takeCourse } = useTakeCourse();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useFirestoreConnect([
    {
      collection: "courses",
      where: [["name.en", "==", `Presenting in a second language`]],
      storeAs: "PWCCourse",
    }
  ]);

  const PWCCourseId = Object.keys(useSelector(state => state.firestore?.data?.PWCCourse) || [])[0];

  const fetchCourse = async () => {
    const pwcCourse = await firestore.collection('courses')
      .where('name.en', '==', 'Presenting in a second language')
      .get();

    return pwcCourse.docs[0].id;
  }

  const fetchUserCourse = async (courseId) => {
    const userCourse = await firestore.collection('users')
      .doc(userId)
      .collection('userCourses')
      .doc(courseId)
      .get();

    return userCourse.data();
  }

  useEffect(() => {
    const fetch = async () => {
      const pwcId = await fetchCourse();
      const pwcCourse = await fetchUserCourse(pwcId);
      if (pwcCourse.results?.values) {
        setValues(pwcCourse.results.values);
      } else if (userLastPwcResult) {
        setValues(userLastPwcResult);
      }
      setUserPWCCourseHTML(pwcCourse.json.pages[pwcCourse.json.pages.length - 8].elements[0].html[language]);
    }
    fetch();

    return () => {
      return false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLastPwcResult]);

  useEffect(() => {
    if (userPWCCourseHTML) {
      setShowSpinner(false);
    }
  }, [userPWCCourseHTML])
  console.log(userPWCCourseHTML);
  if (showSpinner) {
    return <PageSpinner />
  }
  return (
    <div className="pwc-results">
      <PageWrapper>
        <div className="location-header">
          <div>
            <Link to="/">
              {t('resultsPages.bottomButtons.dash')}
            </Link>
            <span className="profile-span long">{courseName[language].long}</span>
            <span className="profile-span short">{courseName[language].short}</span>
          </div>

          <div className="retake-btn">
            <button
              className="button-primary button-primary--desktop  button-primary--remake"
              onClick={() => takeCourse(PWCCourseId, 'take_course', 'Presenting in a second language')}
            >
              {t('resultsPages.buttons')}
            </button>
          </div>
        </div>

        {
          userPWCCourseHTML && values &&
          <JsxParser
            bindings={{ values }}
            components={{ PWCGraph }}
            jsx={userPWCCourseHTML}
          />
        }

      </PageWrapper>

      {
        userPWCCourseHTML && values &&
        <div className="bottom-btns-container">
          <button
            className="button-primary"
            onClick={() => { history.push('/') }}
          >
            {t('resultsPages.bottomButtons.dash')}
          </button>
          <button
            className="button-secondary"
            onClick={() => { history.push('/courses') }}
          >
            {t('resultsPages.bottomButtons.exploreCourses')}
          </button>
        </div>
      }

    </div>
  )
}

export default PWCResults;

const courseName = {
  en: {
    long: "PRESENTING IN A SECOND LANGUAGE",
    short: "PRESENTING..."
  },
  es: {
    long: "Presentar en un segundo idioma",
    short: "Presentar..."
  },
  pt: {
    long: "Apresentação em um segundo idioma",
    short: "Apresentação..."
  },

}