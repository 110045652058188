//react 
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useHistory, Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

//redux
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import AppSlice from "@store/app";

//hooks
import useTakeCourse from '@hooks/useTakeCourse';
import { useShowHeader } from '@hooks/useShowHeader';

//components
import CStyleBackground from '@components/CStyleBackground/CStyleBackground';
import PersonalisedVideo from '@components/PersonalisedVideo/PersonalisedVideo';
import { PrimaryButton, SecondaryButton } from '@components/Buttons/Buttons';
import RecommendedVideo from '@components/RecommendedVideo/RecommendedVideo';
import ExpandableDiv from '@components/ExpandableDiv/ExpandableDiv';
import CustomVideoPlayer from '@components/Video/CustomVideoPlayer';
import PageSpinner from "@components/PageSpinner/PageSpinner";
import PageWrapper from '@components/PageWrapper';
import { FullDot } from '@components/Graph/Dots';
import Graph from '@components/Graph/Graph';

// utils
import { convertCoordinatesToPosition } from '@utils/convertFunctions';

//icons
import { ReactComponent as SendEmail } from "@assets/svg/EmailButton.svg";
import { ReactComponent as InvitePerson } from "@assets/svg/InviteButton.svg";
import { FaArrowDown } from "react-icons/fa";

//styles
import './CStyleResults.css';

const CStyleResults = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showHeader } = useParams();
  useShowHeader(showHeader);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [doShowScrollableArrow, setDoShowScrollableArrow] = useState(true);
  const [initialScrollY, setInitialScrollY] = useState(0);
  const succeedingWithYourStyle = useRef();

  const { takeCourse } = useTakeCourse();
  const userId = useSelector((state) => state.firebase.auth.uid);
  const user = useSelector((state) => state.firestore.data?.users?.[userId]);

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "userCourses", doc: "FcFUxrbSW8bg6RoX1n2g" }],
      storeAs: "myCstyle",
    },
    {
      collection: "courses",
      where: [["name.en", "==", `Building assertiveness`]],
      storeAs: "AssertivenessCourse",
    }
  ]);

  const currentResults = useSelector(state => state.firestore.data?.myCstyle?.results?.cStyle);
  const previousResults = useSelector(state => state.app.lastCompletedCStyle?.results?.cStyle);
  const cStyleResults = currentResults || previousResults;
  const myCStyle = cStyleResults?.cStyleName;
  const value = cStyleResults?.coordinates || {};
  const assertivenessCourseId = Object.keys(useSelector(state => state.firestore?.data?.AssertivenessCourse) || [])[0];
  const shareFeatureEnabled = useSelector(state => state.app?.shareFeature);

  // avoid values near 0 not to be over the line
  const { top, left } = convertCoordinatesToPosition(value);

  const displayModal = (type) => {
    dispatch(AppSlice.actions.setModal({
      visible: true,
      content: {
        type: type
      }
    }))
  }

  function getScrollToPossition() {
    const currentElementPossition = succeedingWithYourStyle?.current?.getBoundingClientRect().bottom;
    const screenHeight = window.innerHeight;
    return Math.abs(screenHeight - currentElementPossition);
  }

  function handleShowScrollableClick() {
    window.removeEventListener('scroll', isVisibleOnScreen, true);
    document.querySelector("body").scrollTo({ left: 0, top: initialScrollY, behavior: 'smooth' });
    setDoShowScrollableArrow(false);
  }

  const isVisibleOnScreen = useCallback(function () {
    const currentElementPossition = succeedingWithYourStyle.current.getBoundingClientRect().bottom;
    const screenHeight = window.innerHeight;
    if (currentElementPossition < screenHeight) {
      window.removeEventListener('scroll', isVisibleOnScreen, true);
      setDoShowScrollableArrow(false);
    }
  }, [succeedingWithYourStyle])

  useEffect(() => {
    if (!initialScrollY) {
      setInitialScrollY(getScrollToPossition());
    }

    window.addEventListener('scroll', isVisibleOnScreen, true);

    return () => window.removeEventListener('scroll', isVisibleOnScreen, true);
  }, [succeedingWithYourStyle.current, cStyleResults]);

  function navigateToSR() {
    window.location.href = `https://simple-revolution.com/c-style-test?ripplUserId=${userId}`;
  }

  function renderHeader() {
    return showHeader !== 'false'
      ? (
        <div className="location-header">
          <div>
            <Link to="/">
              {t("resultsPages.bottomButtons.dash")}
            </Link>
            <span className="profile-c-style-span">{t("resultsPages.cStyle")}</span>
          </div>

          <div className="retake-btn">
            <button
              className="button-primary button-primary--desktop  button-primary--remake"
              onClick={() => takeCourse(user?.mainCourse, 'take_course', 'Finding your C-Style', true)}
            >
              {t("resultsPages.buttons")}
            </button>
          </div>
        </div>
      )
      : (
        <div className="location-header-buttons">
          <SecondaryButton
            onClick={() => handleShowScrollableClick()}
          >
            Read more about your style
          </SecondaryButton>

          <PrimaryButton
            onClick={navigateToSR}
          >
            See how you compare to others
          </PrimaryButton>
        </div>
      )
  }

  function renderBottomButton() {
    return showHeader !== 'false'
      ? (
        <SecondaryButton onClick={() => { history.push('/') }}>
          {t('resultsPages.bottomButtons.dash')}
        </SecondaryButton>
      )
      : (
        <PrimaryButton
          onClick={navigateToSR}
        >
          See how you compare to others
        </PrimaryButton>
      )
  }

  if (!currentResults && !previousResults) return <PageSpinner />
  return (
    <div className="cStyle-results-container">
      <PageWrapper>
        {renderHeader()}

        <div className="c-style-name-container">
          <CStyleBackground />

          <div className="style-text-container">
            <div>
              <h6>{t("resultsPages.yourCStyle")}</h6>
              <h1>{t(`resultsPages.styles.${myCStyle}`)}</h1>
            </div>
          </div>
        </div>

        <p className='static-text'>
          {t("resultsPages.stylesIntro")}
        </p>

        <div id="dot-link1">
          <Graph
            users={[{ left, top }]}
            leftX={t('graph.introvert')}
            rightX={t('graph.extrovert')}
            topY={t('graph.people')}
            bottomY={t('graph.facts')}
            showCStyleLabels={true}
            dotComponent={FullDot}
          />
        </div>

        <div className="main-zone-paragraph">
          <h1>YOUR C-STYLE EXPLAINED…</h1>

          <ExpandableDiv
            header={<p>{cStyleResults?.mainText[language].generalTextParagraph}</p>}
            content={
              <>
                <ul>
                  {cStyleResults?.mainText[language].listItems.map(list =>
                    <li key={uuid()}><span>{list.span}</span> {list.text}</li>
                  )}
                </ul>
              </>
            }
          />


          <h1>UNIQUELY YOU…</h1>
          <ExpandableDiv
            header={<p>Look at your ‘dot’ on the c-styles map. Here’s what your unique location means when it comes to connecting with other c-styles.</p>}
            content={
              <>
                <div className="personalised-paragraph-container">
                  <p className="static-text">{cStyleResults?.personalisedText[language]}</p>
                </div>
              </>
            }
          />

          <div ref={succeedingWithYourStyle}>
            <h1>SUCCEEDING WITH YOUR STYLE…</h1>
            <ExpandableDiv
              header={<p>Your style makes certain things easy, and other things harder. Find out how to use this knowledge to overcome communications barriers!</p>}
              content={
                cStyleResults?.mainText[language].tips.map(tip =>
                  <p key={uuid()}><span>{tip.span}</span> {tip.text}</p>
                )
              }
            />
          </div>
        </div>



        <div className="personalised-video-container page-width">
          <h1>{t('resultsPages.cStylePersonalisedVideo.header')}</h1>
          <p>{t('resultsPages.cStylePersonalisedVideo.paragraph')}</p>
          {
            cStyleVideos.map((video, index) => {
              if (video.cStyleName === myCStyle)
                return (
                  <PersonalisedVideo
                    key={index}
                    src={video.link}
                    bgClass={video.cStyleName}
                    playedFrom={'your_style_in_60'}
                    name={video.cStyleName}
                  />
                )
              return null;
            })
          }
        </div>

        <div className="recommended-video-container">
          <h1>{t('resultsPages.recommendedVideo')}</h1>
          <div className="videos-container">
            {
              cStyleVideos.map((video, index) => {
                if (video.cStyleName !== myCStyle)
                  return (
                    <RecommendedVideo
                      key={index}
                      circle={video.cStyleCircle}
                      insidePicture={video.cStylePicture}
                      videoLink={video.link}
                      h1Text={video.h1Text['en']}
                      pText={video.pText[language]}
                      playedFrom={'other_styles'}
                      name={video.cStyleName}
                    />
                  )
                return null;
              })
            }
          </div>
        </div>

        <div className="bottom-btns-container page-width">
          {renderBottomButton()}
        </div>

        {shareFeatureEnabled && <div className="customHR"></div>}

        {shareFeatureEnabled &&
          <div className="email-container">
            <SecondaryButton onClick={() => displayModal('shareResults')} additionalClasses='sendEmailBtn'>
              <SendEmail />
              <h1>Email results</h1>
            </SecondaryButton>

            <SecondaryButton onClick={() => displayModal('inviteFriend')} additionalClasses='sendInviteBtn'>
              <InvitePerson />
              <h1>Invite friend</h1>
            </SecondaryButton>
          </div>
        }

        <div className="h30px"></div>

        {/* video player for c-style labels */}
        <CustomVideoPlayer />
      </PageWrapper>

      {doShowScrollableArrow &&
        <div className='show-scrollable-container' onClick={handleShowScrollableClick}>
          <div className='show-scrollable'>
            <FaArrowDown />
          </div>
        </div>
      }
    </div>
  )
}

export default CStyleResults

const cStyleVideos = [
  {
    cStyleName: 'connecting',
    cStyleCircle: 'ConnectingCircle',
    cStylePicture: 'ConnectingPicture',
    link: 'https://player.vimeo.com/video/461431074',
    pText: {
      en: 'Discover',
      es: 'Descubrir',
      pt: 'Descobrir'
    },
    h1Text: {
      en: 'CONNECTING',
      es: 'CONECTANDO',
      pt: 'CONNECTING'
    },
  },
  {
    cStyleName: 'influencing',
    cStyleCircle: 'InfluencingCircle',
    cStylePicture: 'InfluencingPicture',
    link: 'https://player.vimeo.com/video/461434024',
    pText: {
      en: 'Discover',
      es: 'Descubrir',
      pt: 'Descobrir'
    },
    h1Text: {
      en: 'INFLUENCING',
      es: 'INFLUENCIAR',
      pt: 'INFUENCIADOR'
    },
  },
  {
    cStyleName: 'examining',
    cStyleCircle: 'ExaminingCircle',
    cStylePicture: 'ExaminingPicture',
    link: 'https://player.vimeo.com/video/461433857',
    pText: {
      en: 'Discover',
      es: 'Descubrir',
      pt: 'Descobrir'
    },
    h1Text: {
      en: 'EXAMINING',
      es: 'EXAMINANDO',
      pt: 'EXAMINADOR'
    },
  },
  {
    cStyleName: 'delivering',
    cStyleCircle: 'DeliveringCircle',
    cStylePicture: 'DeliveringPicture',
    link: 'https://player.vimeo.com/video/464747408',
    pText: {
      en: 'Discover',
      es: 'Descubrir',
      pt: 'Descobrir'
    },
    h1Text: {
      en: 'DELIVERING',
      es: 'ENTREGANDO',
      pt: 'DELIVERING'
    },
  }
]