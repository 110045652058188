import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LoadingScreen from '@components/LoadingScreen';
import useCheckSkipIntroModal from '@hooks/useCheckSkipIntroModal';
import firebase from 'firebase';
import './CustomToken.css';

const CustomToken = () => {
  const history = useHistory();
  const { customToken } = useParams();
  const [isLogged, setIsLogged] = useState(false);
  const { checkSkipIntroModal, checkSkipIntroModalInited } = useCheckSkipIntroModal();

  useEffect(() => {
    if (isLogged && checkSkipIntroModalInited) {
      checkSkipIntroModal();
    }
  }, [checkSkipIntroModalInited, isLogged, checkSkipIntroModal])

  useEffect(() => {

    firebase.auth().signOut()
    const loginCustomToken = async () => {
      if (customToken) {
        const usr = await firebase.auth().signInWithCustomToken(customToken);
        if (usr) setIsLogged(true);
      }
    }

    loginCustomToken();
  }, [customToken, history])

  return (
    <div className="loading-screen-container">
      <LoadingScreen />
    </div>
  )
}

export default CustomToken;
