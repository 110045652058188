const useGenerateAndStoreResults = () => {

	return (survey) => {
		const redoValuesNearOneOrZero = (value) => {
			switch (value) {
				case -1: 
					value = value + 0.0001;
					return value;
				case 1: 
					value = value - 0.0001;
					return value;
				case 0:
					value = value + 0.0001;
					return value;
				default: 
					return value;
			}
		}

		const resultsRange = JSON.parse(JSON.parse(survey.pages[survey.pages.length - 1].elements[0].html));

		const finalResults = {
			cStyleName: '',
			mainText: '',
			personalisedText: '',
			coordinates: { x: 0, y: 0 },
			subzoneName: ''
		};
		//build questions array
		const questions = [];

		survey.pages.forEach(page => {
			if (page.elementsValue) {
				if (page.elementsValue[0].propertyHash.axis) {
					questions.push({
						name: page.elementsValue[0].propertyHash.name,
						axis: page.elementsValue[0].propertyHash.axis
					})
				}
			}
		})

		const userAnswers = survey.data;

		const userResults = {
			x: {
				value: 0,
				questionCount: 0
			},
			y: {
				value: 0,
				questionCount: 0
			}
		}

		questions.forEach(question => {
			userResults[question.axis].value = Number(userResults[question.axis].value) + Number(userAnswers[question.name]);
			userResults[question.axis].questionCount++;
		})

	  	const usersValues = {
			x: redoValuesNearOneOrZero(userResults.x.value / (userResults.x.questionCount * 2)),
			y: redoValuesNearOneOrZero(userResults.y.value / (userResults.y.questionCount * 2)),
		}

		console.log(usersValues);

		finalResults.coordinates.x = usersValues.x;
		finalResults.coordinates.y = usersValues.y; 
	
		const bigZone = resultsRange.find((z) => 
			(z.range[0].x <= usersValues.x && usersValues.x < z.range[1].x) && 
			(z.range[0].y <= usersValues.y && usersValues.y < z.range[1].y));

			console.log(bigZone);

		if (bigZone) {
			finalResults.cStyleName = bigZone.name;
			finalResults.mainText = bigZone.mainText;
			
			const subZone = bigZone.zones.find((z) => 
				(z.range[0].x < usersValues.x && usersValues.x <= z.range[1].x) && 
				(z.range[0].y < usersValues.y && usersValues.y <= z.range[1].y));

			if (subZone) {
				finalResults.subzoneName = subZone.name;
				finalResults.personalisedText = subZone.text;
				return finalResults;
			} else {
				throw new Error(`No sub zone found.`)
			}
		} else {
			throw new Error(`No main zone found.`)
		}
	}
}

export default useGenerateAndStoreResults;