import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { isEmpty, useFirebase } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import AppSlice from "@store/app";

import PageWrapper from "@components/PageWrapper";
import Flags from "@components/Flags/Flags";

import { signInURL } from "@/config.js";

import classnames from "classnames";
import firebase from "firebase/app";

import "./style.css";

const Login = (props) => {
    const [sent, setSent] = useState(false);
    const [emailResent, setEmailResent] = useState(false);
    const signing = firebase.auth().isSignInWithEmailLink(window.location.href);
    const [signInURLChecked, setSignInURLChecked] = useState(false);
    const [email, setEmail] = useState(
        window.localStorage.getItem("emailForSignIn") || ''
    );
    const [isNewUser, setIsNewUser] = useState(null);
    const [invalidEmail, setInvalidEmail] = useState(false);

    const auth = useSelector((state) => state.firebase.auth);

    const history = useHistory();

    const dispatch = useDispatch();

    const reduxFirebase = useFirebase();

    const { t } = useTranslation();

    const doLogin = () => {
        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: signInURL,
            // This must be true.
            handleCodeInApp: true,
        };
        return firebase
            .auth()
            .sendSignInLinkToEmail(email, actionCodeSettings)
            .then(() => {
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem("emailForSignIn", email);
                setSent(true);
            })
            .catch((error) => {
                console.log(error);
                if (error.code === "auth/invalid-email") {
                    setInvalidEmail(true);
                }
            });
    };

    useEffect(() => {
        dispatch(
            AppSlice.actions.setHeader({ visible: true, showHamburger: false })
        );
    }, [dispatch]);

    useEffect(() => {
        const checkSignInURL = () => {
            // Confirm the link is a sign-in with email link.
            if (signing) {
                let signEmail = email;
                if (!signEmail) {
                    signEmail = window.prompt(
                        "Please provide your email for confirmation"
                    );
                    window.localStorage.setItem(
                        "emailForSignIn",
                        signEmail.toLowerCase()
                    );
                }
                // The client SDK will parse the code from the link for you.
                firebase
                    .auth()
                    .signInWithEmailLink(signEmail, window.location.href)
                    .then((result) => {
                        // create profile
                        return (async () => {
                            let enabled = false;
                            do {
                                try {
                                    const token = await firebase
                                        .auth()
                                        .currentUser.getIdTokenResult(true);
                                    const claim =
                                        token &&
                                        token.claims &&
                                        token.claims.role;
                                    if (["user", "admin"].includes(claim)) {
                                        enabled = true;
                                    }
                                } catch (error) {
                                    throw error;
                                }
                            } while (!enabled);
                            return result;
                        })();
                    })
                    .then((result) => {
                        if (result.additionalUserInfo.isNewUser) {
                            setIsNewUser(true);
                            dispatch(AppSlice.actions.setWelcome(true));
                        } else {
                            setIsNewUser(false);
                        }
                    })
                    .catch((error) => {
                        // Some error occurred, you can inspect the code: error.code
                        // Common errors could be invalid email and invalid or expired OTPs.
                        console.log(error);
                        reduxFirebase.logout();
                        history.push("/login");
                    });
                setSignInURLChecked(true);
            }
        };

        if (!signInURLChecked) {
            checkSignInURL();
        }
    }, [signInURLChecked, email, signing, history, reduxFirebase, dispatch]);

    useEffect(() => {
        if (!isEmpty(auth)) {
            if (isNewUser === true) {
                history.push("/completeProfile");
            } else if (isNewUser === false) {
                history.push("/");
            }
        }
    }, [auth, history, isNewUser]);

    const changeEmail = (e) => {
        setInvalidEmail(false);
        setEmail(e.target.value.toLowerCase());
    };

    const showModal = (type) => {
        dispatch(
            AppSlice.actions.setModal({
                visible: true,
                content: { type: type },
            })
        );
    };

    const resendEmail = () => {
        doLogin().then(() => {
            setEmailResent(true);
        });
    };

    return (
        <div className="login">
            <PageWrapper className="full-height">
                {sent ? (
                    <>
                        <div className="login__link-sent">
                            <p className="subtitle">{t("login.emailSentPage.action")}</p>
                            <h1 className="big">{t("login.emailSentPage.pageHeader")}</h1>
                            <h3>{t("login.emailSentPage.sentConfirmationText")} {email}</h3>
                            <p>
                                {t("login.emailSentPage.wrongReceiver.text")}
                                <span onClick={() => setSent(false)}>
                                    {t("login.emailSentPage.wrongReceiver.link")}
                                </span>
                                .
                            </p>
                            <p>
                                {t("login.emailSentPage.nextStepInstructions")}
                            </p>
                        </div>
                        <button
                            className={classnames("button-secondary", {
                                "button-secondary--disabled": emailResent,
                            })}
                            onClick={resendEmail}
                            disabled={emailResent}
                        >
                            {emailResent ? t("login.emailSentPage.button.sent") : t("login.emailSentPage.button.resend")}
                        </button>
                    </>
                ) : signing ? (
                    "Signing in..."
                ) : (
                    <>  
                        <div className="login__content">
                            <div className="action-flags">
                                <p className="subtitle">{t("login.sendEmailPage.action")}</p>
                                <div className="flags-box">
                                    <Flags />
                                </div>
                            </div>
                            <h1 className="big">{t("login.sendEmailPage.pageHeader")}</h1>
                            <h3>
                                {t("login.sendEmailPage.mainHeader")}
                            </h3>
                            <input
                                className={classnames({ error: invalidEmail })}
                                name={"em" + Math.random()}
                                id={"em" + Math.random()}
                                type="email"
                                placeholder={t("login.sendEmailPage.inputPlaceholder")}
                                value={email}
                                onChange={changeEmail}
                                onKeyUp={(e) => {
                                    if (e.key.toLowerCase() === "enter") {
                                        doLogin();
                                    }
                                }}
                            />
                            {invalidEmail && (
                                <p className="smaller smaller--error">
                                    {t("login.sendEmailPage.invalidInput")}
                                </p>
                            )}
                        </div>

                        <div className="login__cta">
                            <p className="smaller">
                                {t("login.sendEmailPage.aknowledge.text")}
                                <span
                                    onClick={() => {
                                        showModal("termsAndConditions");
                                    }}
                                >
                                    {t("login.sendEmailPage.aknowledge.termsAndConditions")}
                                </span>
                                ,{" "}
                                <span
                                    onClick={() => {
                                        showModal("privacyPolicy");
                                    }}
                                >
                                    {t("login.sendEmailPage.aknowledge.privacyPolicy")}
                                </span>{" "}
                                {t("login.sendEmailPage.aknowledge.text2")}
                                <span
                                    onClick={() => {
                                        showModal("cookiePolicy");
                                    }}
                                >
                                    {t("login.sendEmailPage.aknowledge.cookiePolicy")}
                                </span>
                                .
                            </p>
                            <button
                                className={classnames("button-primary", {
                                    "button-primary--disabled": !email,
                                })}
                                onClick={doLogin}
                                disabled={!email}
                            >
                                {t("login.sendEmailPage.getLinkButton")}
                            </button>
                        </div>
                    </>
                )}
            </PageWrapper>
        </div>
    );
};

export default Login;
