import React from "react";
import { useSelector /*, useDispatch*/ } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";

//import AppSlice from "@store/app";

import { TimeProgressBar } from "@components/ProgressBar";
//import ActionPanel from "@components/ActionPanel";
//import PodcastPlayer from "@components/PodcastPlayer";
import PodcastTile from "@components/PodcastTile";
import PageWrapper from "@components/PageWrapper";
import NotFound from "@components/NotFound";

import "./style.css";

const PodcastDetail = () => {
    const { podcastId } = useParams();

    const userId = useSelector((state) => state.firebase.auth.uid);
    //const dispatch = useDispatch();

    useFirestoreConnect([
        {
            collection: "users",
            doc: userId,
            subcollections: [{ collection: "userPodcasts" }],
            storeAs: "myPodcasts",
        },
        { collection: "podcasts" },
    ]);

    const podcast = useSelector(
        (state) => state.firestore.data?.myPodcasts?.[podcastId]
    );

    const podcastDefinition = useSelector(
        (state) => state.firestore.data?.podcasts?.[podcastId]
    );

    const allPodcasts = useSelector((state) => state.firestore.data?.podcasts);

    // check if podcast exists
    if (isLoaded(allPodcasts) && !(podcastId in allPodcasts)) {
        return <NotFound />;
    }

    return (
        <div className="podcast-detail">
            <PageWrapper>
                {/* <Link className="podcast-detail__back-link" to="/podcasts">
                    Podcasts
                </Link> */}

                <div className="podcast-detail__title">
                    <h2>{podcastDefinition?.title}</h2>

                    {/* <ShareIcon /> */}
                </div>
                <div className="podcast-detail__tile">
                    <div className="podcast-detail__image">
                        <PodcastTile podcast={podcast} isDetail />
                        <TimeProgressBar
                            className={"podcast-tile__progress-bar"}
                            value={podcast?.time}
                            maxValue={podcast?.duration}
                            time
                        />
                    </div>

                    <hr />
                    <div className="podcast-detail__summary">
                        <h4>Summary</h4>
                        <p>{podcastDefinition?.summary}</p>
                    </div>
                </div>
            </PageWrapper>
        </div>
    );
};

export default PodcastDetail;
