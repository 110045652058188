import React, { useEffect, useState } from "react";
import "scroll-behavior-polyfill";

// import logo from '@assets/svg/logo.svg';
import "./style.css";

import { rrfConfig } from "@/config.js";
import { firebaseConfig } from "@/firebaseConfig.js";

import store from "@/store";

import { Provider, useSelector, useDispatch } from "react-redux";
import firebase from "firebase/app";
import throttle from "lodash.throttle";
import "firebase/auth";
import "firebase/firestore"; // <- needed if using firestore
// import 'firebase/functions' // <- needed if using httpsCallable
import { createFirestoreInstance } from "redux-firestore"; // <- needed if using firestore
import {
    ReactReduxFirebaseProvider,
    isLoaded,
    isEmpty,
    useFirestoreConnect,
} from "react-redux-firebase";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import Login from "@components/Login";
import UpdateProfile from "@components/UpdateProfile";
import Header from "@components/Header";
//import Footer from "@components/Footer";
import Dashboard from "@components/Dashboard";
import TopicsPage from "@components/TopicsPage";
import PodcastsPage from "@components/PodcastsPage";
import PodcastDetail from "@components/PodcastDetail";
import SurveyComponent from "@components/SurveyComponent";
import { init } from "@store/app";
import { initUserCourses } from "@store/userCourses";
import About from "@components/About";
import GenericContent from "@components/GenericContent";
import Modal from "@components/Modal";
// import Profile from "@components/Profile";
import TopicsDetail from "@components/TopicsDetail";
import ConfLogin from "@components/ConfLogin/ConfLogin";
import LoadingScreen from "@components/LoadingScreen";
import Certifications from "@components/Certifications";
import ScrollToTop from "@components/ScrollToTop";
import PodcastPlayer from "@components/PodcastPlayer";
import CStyleResults from "@components/CStyleResults/CStyleResults.js";
import NotFound from "@components/NotFound";
import LevelListener from "@components/LevelListener";
import AppSlice from "@store/app";
import AssertivenessResults from '@components/AssertivenessResults/AssertivenessResults'
import Analytics from "@components/Analytics";
import PWCResults from "@components/PWCResults/PWCResults";
import GlobalMapPage from "@components/GlobalMapPage/GlobalMapPage";
import CustomToken from "@components/CustomToken/CustomToken";
import SiSlice from "@store/si";
import CustomModal from "@components/Modal/CustomModal";
import OpenReplay from '@openreplay/tracker';
import { useTranslation } from "react-i18next";
import PreResultsSurvey from "@components/PreResultsSurvey/PreResultsSurvey";

let tracker = {};

const updateVh = throttle(() => {
    // set a vh custom property on the document root
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
}, 1000);

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance, // <- needed if using firestore
};

const IsInited = (props) => {
    const auth = useSelector((state) => state.firebase.auth);
    const dispatch = useDispatch();

    const [inited, setInited] = useState(false);
    const [allowMultiLingual, setAllowMultiLingual] = useState(false);

    useEffect(() => {
        updateVh();
    });

    useEffect(() => {
        window.addEventListener("resize", updateVh);
        return () => {
            window.removeEventListener("resize", updateVh);
        };
    }, []);

    const { i18n } = useTranslation();

    useEffect(() => {
        if (allowMultiLingual) {
            const preferredLanguage = window.localStorage.getItem('rippl-pref-language');
            if (preferredLanguage) {
                const language = preferredLanguage.slice(0, 2);
                i18n.changeLanguage(language);
            } else if (i18n.language) {
                const language = i18n.language.slice(0, 2);
                i18n.changeLanguage(language)
            }
        } else {
            i18n.changeLanguage('en')
        }
    }, [i18n.language, allowMultiLingual]);

    const remoteConfigLoaded = new Promise((resolve) => {
        const remoteConfig = firebase.remoteConfig();
        if (remoteConfig) {
            remoteConfig
                .fetchAndActivate()
                .then(() => {
                    const privacyUrl = remoteConfig.getValue("privacy_policy")
                        ._value;
                    const termsUrl = remoteConfig.getValue(
                        "terms_and_conditions"
                    )._value;
                    const scienceEvents = JSON.parse(remoteConfig.getValue(
                        "science_events"
                    )._value);
                    const openReplayId = remoteConfig.getValue(
                        "openreplay_project_id"
                    )._value;
                    const shareFeature = remoteConfig.getValue(
                        "share_feature"
                    )._value;
                    const skipIntroModal = remoteConfig.getValue(
                        "skip_intro_modal"
                    )._value;
                    const allowMultiLingual = remoteConfig.getValue(
                        "allow_multi_lingual"
                    )._value;
                    const inviteFriendEmailTemplate = remoteConfig.getValue(
                        "invite_friend_email_template"
                    )._value;
                    const inviteFriendEmailSubject = remoteConfig.getValue(
                        "invite_friend_email_subject"
                    )._value;
                    const shareResultsEmailTemplate = remoteConfig.getValue(
                        "share_results_email_template"
                    )._value;
                    const shareResultsEmailSubject = remoteConfig.getValue(
                        "share_results_email_subject"
                    )._value;
                    const definedEmailBody = remoteConfig.getValue(
                        "defined_email_body"
                    )._value;
                    // Check if OpenReplay tracker was initialized. If it wasn't then use openReplayId to init it
                    if (!tracker.isInited && openReplayId) {
                        tracker = new OpenReplay({
                            projectKey: openReplayId,
                            heatmaps: false
                        });
                        tracker.isInited = true;
                        tracker.start();
                    }

                    dispatch(
                        AppSlice.actions.fbRemoteConfig({
                            privacyPolicy: privacyUrl,
                            termsAndConditions: termsUrl,
                        })
                    );
                    dispatch(
                        SiSlice.actions.setScienceEvents({
                            events: scienceEvents
                        })
                    )
                    dispatch(
                        AppSlice.actions.setShareFeature(shareFeature === 'true')
                    );
                    dispatch(
                        AppSlice.actions.setSkipIntroModal(skipIntroModal)
                    );
                    dispatch(
                        AppSlice.actions.setAllowMultiLingual(allowMultiLingual)
                    );
                    dispatch(
                        AppSlice.actions.setInviteFriendEmail({
                            template: inviteFriendEmailTemplate,
                            subject: inviteFriendEmailSubject
                        })
                    );
                    dispatch(
                        AppSlice.actions.setShareResultsEmail({
                            template: shareResultsEmailTemplate,
                            subject: shareResultsEmailSubject
                        })
                    );
                    dispatch(
                        AppSlice.actions.setDefinedEmailBody(definedEmailBody)
                    );
                    setAllowMultiLingual(allowMultiLingual);
                })
                .then(() => {
                    resolve();
                });
        }
    });

    const authLoaded = new Promise((resolve) => {
        if (isLoaded(auth)) {
            resolve();
        }
    });

    const timePassed = new Promise((resolve) => setTimeout(resolve, 800));

    Promise.all([authLoaded, remoteConfigLoaded, timePassed]).then(() => {
        setInited(true);
    });

    return inited ? props.children : props.loader;
};

const ShowLoader = (props) => {
    return <LoadingScreen />;
};

const ShowHeader = () => {
    const header = useSelector((state) => state.app.header);
    return header.visible ? (
        <Header showHamburger={header.showHamburger} />
    ) : null;
};

/*
const ShowFooter = () => {
    const footer = useSelector((state) => state.app.footer);
    return footer ? <Footer /> : null;
};
*/

const ShowModal = () => {
    const modal = useSelector((state) => state.app.modal);
    return modal.visible ? <Modal content={modal.content} /> : null;
};

const ShowCustomModal = () => {
    const modal = useSelector(state => state.si.customModal)
    return modal.visible ? <CustomModal content={modal.content} /> : null;
};

const PrivateRoute = ({ requiresMainCourse, component: Children, ...rest }) => {
    const auth = useSelector((state) => state.firebase.auth);
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const userId = useSelector((state) => state.firebase.auth.uid);
    const isStylePending = useSelector((state) => state.app.isStylePending);

    const [inited, setInited] = useState();

    useFirestoreConnect([{ collection: "users", doc: userId }]);

    const user = useSelector((state) => state.firestore.data?.users?.[userId]);

    //set openReplay tracker userId
    useEffect(() => {
        if (userId && tracker.isInited) {
            tracker.setUserID(userId);
        }
    }, [userId])

    useEffect(() => {
        if (!isEmpty(auth) && !isEmpty(user) && !inited) {
            init(dispatch, userId);
            initUserCourses(dispatch, userId);
            setInited(true);
        }
    }, [auth, inited, dispatch, userId, user]);

    useEffect(() => {
        if (user && user.language) {
            window.localStorage.setItem('rippl-pref-language', user.language);
            i18n.changeLanguage(user.language);
        }
    }, [user])

    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isEmpty(auth) ? (
                    requiresMainCourse && isStylePending ? (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: {
                                    from: location,
                                    showmodal: true,
                                },
                            }}
                        />
                    ) : (
                        <>
                            <Children {...location} />
                            <LevelListener />
                        </>
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

function App() {
    const [loaded, setLoaded] = useState(false);

    const configureFirebase = async () => {
        if (window.location.hostname === "localhost") {
            if (!firebase.apps.length) {
                firebase.initializeApp(firebaseConfig);
                //     //  firebase.auth().useEmulator('http://localhost:9099/');
                //     //  firebase.firestore().useEmulator("localhost", 8090);
                //     //} else {
                firebase.firestore();
                //     //}
            }
        } else {
            const response = await fetch("/__/firebase/init.json");
            const config = await response.json();
            if (!firebase.apps.length) {
                firebase.initializeApp(config);
                firebase.firestore();
            }
        }
    };

    useEffect(() => {
        configureFirebase().then(() => setLoaded(true));
    }, []);

    return (
        <>
            {loaded && (
                <Provider store={store}>
                    <ReactReduxFirebaseProvider {...rrfProps}>
                        <IsInited loader={<ShowLoader />} >
                            <Router>
                                <ScrollToTop />
                                <ShowModal />
                                <ShowCustomModal />
                                <ShowHeader />
                                <Switch>
                                    <Route path="/(login|completeSignIn)">
                                        <Login />
                                    </Route>
                                    <Route exact path="/customToken/:customToken">
                                        <CustomToken />
                                    </Route>
                                    <Route exact path="/conf_login/:confId">
                                        <ConfLogin />
                                    </Route>
                                    <Route exact path="/events/:confId">
                                        <ConfLogin />
                                    </Route>
                                    <Route exact path="/conference-results/:conferenceId?/:showHeader?/:showPannel?/:showCharts?/:ripplUserId?">
                                        <GlobalMapPage />
                                    </Route>
                                    {/* any other public route here */}
                                    <PrivateRoute
                                        exact
                                        path="/help"
                                        component={() =>
                                            <GenericContent
                                                content={{ type: "help" }}
                                            />
                                        }
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/about"
                                        component={() => <About />}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/completeProfile"
                                        component={() => <UpdateProfile />}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/course/:courseId"
                                        component={() => <SurveyComponent />}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/courses"
                                        requiresMainCourse
                                        component={(props) => (
                                            <TopicsPage {...props} />
                                        )}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/course-details/:topicId"
                                        requiresMainCourse
                                        component={(props) => (
                                            <TopicsDetail {...props} />
                                        )}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/podcasts"
                                        requiresMainCourse
                                        component={(props) => (
                                            <PodcastsPage {...props} />
                                        )}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/podcast/:podcastId"
                                        requiresMainCourse
                                        component={(props) => (
                                            <PodcastDetail {...props} />
                                        )}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/certificates"
                                        requiresMainCourse
                                        component={(props) => (
                                            <Certifications {...props} />
                                        )}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/pre-results"
                                        component={() => <PreResultsSurvey />}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/c-style/:showHeader?"
                                        component={() => <CStyleResults />}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/pwc"
                                        component={() => <PWCResults />}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/assertiveness-results"
                                        component={() => <AssertivenessResults />}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/"
                                        component={(props) => (
                                            <Dashboard {...props} />
                                        )}
                                    />
                                    {/* <PrivateRoute
                                        exact
                                        path="/conference-results"
                                        component={() => <GlobalMapPage />}
                                    /> */}
                                    <PrivateRoute>
                                        <NotFound />
                                    </PrivateRoute>
                                </Switch>
                                {/* <ShowFooter /> */}
                                <PodcastPlayer />
                                <Analytics />
                            </Router>
                        </IsInited>
                    </ReactReduxFirebaseProvider>
                </Provider>
            )}
        </>
    );
}

export default App;