import React from 'react'
import Vimeo from "@u-wave/react-vimeo";
import { stopVideo } from '@utils/appUtils';
import { useSelector, useDispatch } from 'react-redux';
import { IoCloseCircleOutline } from "react-icons/io5";

const CustomVideoPlayer = () => {
  
  const video = useSelector(state => state.app.videoPlayer);
  const dispatch = useDispatch();

  return (
    <div 
      className={`${video && video.isPlaying ? 'labels-video-playing' : 'labels-video-player'}`}
      onClick={(e) => stopVideo(e, dispatch, video)}
    >
      { video && video.source   
          ?<div className="video-modal">
              <div className="video-modal__video-wrapper">
                <canvas width="9" height="16" />
                <div className="video-modal__video-wrapper--close">
                    <IoCloseCircleOutline onClick={(e) => stopVideo(e, dispatch, video)} />
                </div>
                <Vimeo
                  video={video.source}
                  autoplay
                  muted={false}
                  width="100%"
                  height="100%"
                  className="video-modal__vimeo"
                />
              </div>
          </div>
          : null
      }
    </div>
  )
}

export default CustomVideoPlayer;