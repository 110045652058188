import React from "react";
import classnames from "classnames";

//components
import PersonalisedVideo from "@components/PersonalisedVideo/PersonalisedVideo";
import CustomVideoPlayer from "@components/Video/CustomVideoPlayer";
import Image from "@components/Image";

//styles
import "./style.css";

const Instructions = ({ children, rankingExample, persVideo }) => {

    const renderChildren = () => {
        const elements = React.Children.toArray(children);

        return (
            <div
                className={classnames("instructions__content", {
                    "instructions__content--image": rankingExample,
                })}
            >
                {elements}
                {rankingExample && (
                    <div className="instructions__content--image__wrapper">
                        <Image
                            src={require("@images/ranking-example.gif").default}
                            alt="Activity example"
                        />
                    </div>
                )}
                {persVideo && (
                    <div className="personalised-video-container">
                        <PersonalisedVideo 
                            src={persVideo.src}
                            bgClass={persVideo.bg}
                            name="PISL_general_video"
                            playedFrom="survey_component"
                        />   
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <div className="instructions__content">
                <div className="instructions__content__background" />
                {renderChildren()}
            </div>
            <CustomVideoPlayer />
        </>
    );
};

export default Instructions;
