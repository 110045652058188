import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoPlay } from 'react-icons/io5'
import { playVideo } from '@utils/appUtils';
import { v4 as uuid } from 'uuid';

// Redux
import { useDispatch } from "react-redux";

// Pictures and icons
import { ReactComponent as Background } from "@images/background.svg";
import { RiPlayCircleLine } from 'react-icons/ri';

// Styles
import cx from "classnames";
import './Graph.css';

const Graph = ({
    users,
    leftX,
    rightX,
    topY,
    bottomY,
    showCStyleLabels,
    stylesResults,
    dotComponent: DotComponent,
    conferenceJobs
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const connectingVideo = 'https://player.vimeo.com/video/461431074';
    const influencingVideo = 'https://player.vimeo.com/video/461434024';
    const examiningVideo = 'https://player.vimeo.com/video/461433857';
    const deliveringVideo = 'https://player.vimeo.com/video/464747408';

    const dotsElements = users.map(user => {
        const dotColor = user.dotColor || (user.job && conferenceJobs?.[user.job]?.backgroundColor) || undefined;
        const showDotColor = user.showDotColor || (user.job && conferenceJobs?.[user.job]?.showDotColor) || false;

        const key = uuid();
        return <DotComponent
            key={key}
            top={user.top}
            left={user.left}
            pulse={user.pulse}
            number={user.userNumber}
            dotColor={showDotColor ? dotColor : null}
            conferenceJobs={conferenceJobs}
        />
    })

    return (
        <div className="graph-container">
            <div className="c-style-graph">
                <div className="c-style-graph-wrapper">
                    <span className="labels labels__introvert">
                        {leftX}
                    </span>
                    <span className={`labels labels__extrovert`}>
                        {rightX}
                    </span>
                    <span className="labels labels__people">
                        {topY}
                    </span>
                    <span className="labels labels__facts">
                        {bottomY}
                    </span>

                    {/* TO DO: Create components for those labels text on c-style map */}
                    <div className={`connecting ${showCStyleLabels ? 'show' : 'hide'} `}>
                        <div className="label-container">
                            <Background
                                className={cx(
                                    "labels-mutual",
                                    `bg-connecting`
                                )}
                            />
                            <div className="labels-text" onClick={() => playVideo(dispatch, connectingVideo, 'connecting', 'map')}>
                                {stylesResults &&
                                    <div className="numbers-container">
                                        <Background
                                            className={cx(
                                                `bg-connecting`
                                            )}
                                        />
                                        <h3 className="numbers-container__text">{stylesResults.connecting.total}</h3>
                                    </div>
                                }
                                <span className="labels-text__icon"><RiPlayCircleLine /></span>
                                <h3 className="labels-text__title">{t('graph.connecting')}</h3>
                            </div>
                        </div>
                    </div>

                    <div className={`influencing ${showCStyleLabels ? 'show' : 'hide'} `}>
                        <div className="label-container">
                            <Background
                                className={cx(
                                    "labels-mutual",
                                    `bg-influencing`
                                )}
                            />
                            <div className="labels-text" onClick={() => playVideo(dispatch, influencingVideo, 'influencing', 'map')}>
                                {stylesResults &&
                                    <div className="numbers-container">
                                        <Background
                                            className={cx(
                                                `bg-influencing`
                                            )}
                                        />
                                        <h3 className="numbers-container__text">{stylesResults.influencing.total}</h3>
                                    </div>
                                }
                                <span className="labels-text__icon"><RiPlayCircleLine /></span>
                                <h3 className="labels-text__title">{t('graph.influencing')}</h3>
                            </div>
                        </div>
                    </div>

                    <div className={`examining ${showCStyleLabels ? 'show' : 'hide'} `}>
                        <div className="label-container">
                            <Background
                                className={cx(
                                    "labels-mutual",
                                    `bg-examining`
                                )}
                            />
                            <div className="labels-text" onClick={() => playVideo(dispatch, examiningVideo, 'examining', 'map')}>
                                {stylesResults &&
                                    <div className="numbers-container">
                                        <Background
                                            className={cx(
                                                `bg-examining`
                                            )}
                                        />
                                        <h3 className="numbers-container__text">{stylesResults.examining.total}</h3>
                                    </div>
                                }
                                <span className="labels-text__icon"><RiPlayCircleLine /></span>
                                <h3 className="labels-text__title">{t('graph.examining')}</h3>
                            </div>
                        </div>
                    </div>

                    <div className={`delivering ${showCStyleLabels ? 'show' : 'hide'} `}>
                        <div className="label-container">
                            <Background
                                className={cx(
                                    "labels-mutual",
                                    `bg-delivering`
                                )}
                            />
                            <div className="labels-text" onClick={() => playVideo(dispatch, deliveringVideo, 'delivering', 'map')}>
                                {stylesResults &&
                                    <div className="numbers-container">
                                        <Background
                                            className={cx(
                                                `bg-delivering`
                                            )}
                                        />
                                        <h3 className="numbers-container__text">{stylesResults.delivering.total}</h3>
                                    </div>
                                }
                                <span className="labels-text__icon"><RiPlayCircleLine /></span>
                                <h3 className="labels-text__title">{t('graph.delivering')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="c-style-graph-wrapper__dot-boundary">
                        {dotsElements}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Graph;