import React from 'react';
import './JobLegend.css';

const JobLegend = ({
  text,
  color,
  percent,
  handleClick
}) => {
  return (
    <div
      onClick={handleClick}
      className="job-legend"
    >
      <div
        className="job-legend__color"
        style={{
          backgroundColor: color
        }}
      />
      <p className="job-legend__title">
        {text}
      </p>
    </div>
  )
}

export default JobLegend