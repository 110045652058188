import React from "react";
import { useSelector } from "react-redux";

import LiveConferencePreSurvey from "@components/LiveConferencePreSurvey/LiveConferencePreSurvey";
import SimpleRevolutionSurvey from "@components/SimpleRevolutionSurvey/SimpleRevolutionSurvey";

const PreResultsSurvey = () => {
  const uid = useSelector(state => state.firebase.auth?.uid);
  const conference = useSelector(state => state.firestore?.data?.users?.[uid]?.conference);
  const userNumber = useSelector(state => state.firestore?.data?.users?.[uid]?.numberInConference);
  const {
    showedUserNumber,
    conferenceJobs,
    ages,
    genders,
    isSimpleRevolution
  } = useSelector(state => state.conference || {});

  if (isSimpleRevolution) {
    return <SimpleRevolutionSurvey
      uid={uid}
      conference={conference}
      userNumber={userNumber}
      showedUserNumber={showedUserNumber}
      conferenceJobs={conferenceJobs}
      ages={ages}
      genders={genders}
    />
  }

  return <LiveConferencePreSurvey
    uid={uid}
    conference={conference}
    userNumber={userNumber}
    showedUserNumber={showedUserNumber}
    conferenceJobs={conferenceJobs}
  />
}

export default PreResultsSurvey;