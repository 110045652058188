import { useCallback, useState, useEffect } from 'react';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { EVENTS, analyticsLogEvent } from '@components/Analytics';
import AppSlice from "@store/app";

const useTakeCourse = () => {
  useFirestoreConnect([{ collection: "courses" }]);

  const dispatch = useDispatch();
  const firestore = useFirestore();
  const history = useHistory();
  const userId = useSelector(state => state.firebase.auth.uid);
  const [coursesInited, setCoursesInited] = useState(false);
  const conference = useSelector(state => state.conference.name || '');

  const isLiteUser = useSelector(state => {
    if (typeof state.firestore.data.users === 'object' && state.firestore.data.users !== null) {
      return Object.entries(state.firestore.data.users)[0][1]?.liteUser;
    } else {
      return true;
    }
  });

  const coursesAllowLiteUsers = useSelector(state => {
    const courses = []
    if (state.firestore && state.firestore.data && state.firestore.data.courses) {
      Object.keys(state.firestore.data.courses).forEach(key => {
        if (state?.firestore?.data?.courses[key].allowLite) {
          courses.push(key)
        }
      })
    }
    return courses;
  })

  useEffect(() => {
    if (coursesAllowLiteUsers.length > 0) {
      setCoursesInited(true);
    }
  }, [coursesAllowLiteUsers])

  const takeCourse = useCallback(
    (courseId, status, courseName) => {
      let eventName = '';

      if (isLiteUser && !coursesAllowLiteUsers.includes(courseId)) {
        // Add some analytics logs here

        dispatch(AppSlice.actions.setModal({
          visible: true,
          content: {
            type: 'contactUs'
          }
        }))
      } else {
        switch (status.toLowerCase()) {
          case 'go':
            eventName = 'take_course';
            break;
          case 'continue':
            eventName = 'continue_course';
            break;
          case 'retake':
            eventName = 'retake_course';
            break;
          default:
            eventName = 'start_course';
        }

        analyticsLogEvent(
          conference +
          EVENTS.ev_user_start_course +
          courseName
        )

        const start = new Date();
        firestore
          .update(
            {
              collection: `users/${userId}/userCourses`,
              doc: `${courseId}`,
            },
            {
              results: firestore.FieldValue.delete(),
              timeCompleted: null,
              timeStarted: null,
            }
          )
          .then(() => {
            const end = new Date();
            history.push(`/course/${courseId}`);
          });
      }
    },
    [
      history,
      firestore,
      userId,
      coursesAllowLiteUsers,
      isLiteUser,
      dispatch,
      conference
    ]
  );

  return { takeCourse, coursesInited };
}

export default useTakeCourse
