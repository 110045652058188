import React, { useState, useRef, useEffect } from 'react';
import PageWrapper from '@components/PageWrapper';
import './TrueOrFalse.css';

const TrueOrFalse = ({ question, answers = [], correctAnswer }) => {
  const [userDidAnswer, setUserDidAnswer] = useState();
  const [userAnswers, setUserAnswers] = useState([]);

  useEffect(() => {
    if (answers.length && !userAnswers.length) {
      const answersCopy = answers.map(answer => {
        return {
          state: '',
          text: answer,
          isUserPick: ''
        }
      });
      setUserAnswers(answersCopy);
    }
  }, [answers])

  function handleClick(userPick) {
    const userAnswersCopy = userAnswers.map((answer, index) => {
      return {
        state: index === correctAnswer ? 'correct' : 'incorrect',
        text: answer.text,
        isUserPick: index === userPick ? 'isUserPick' : ''
      }
    });
    setUserAnswers(userAnswersCopy);
    setUserDidAnswer(true);
  }

  const ButtonsElments = userAnswers.map((answer, index) => {
    return (
      <div
        key={answer.text}
        className={`btn-container ${answer.isUserPick}`}
      >
        <button
          className={`btn ${answer.state} ${!userDidAnswer && 'btn-hover'}`}
          onClick={() => handleClick(index)}
          disabled={userDidAnswer}
        >
          {answer.text}
        </button>
      </div>
    )
  })


  return (
    <PageWrapper>
      <div className='trueOrFalse'>
        <div>
          <div className='header'>
            <p className='header__subtitle'>True or false?</p>
            <h1 className='header__title'>Telling a good story while you present will get your audience on your same wavelength.</h1>
          </div>

          <div className='buttons-container'>
            {ButtonsElments}
            {/* <button onClick={() => setUserDidAnswer(true)} className='btn correct'>True</button>
            <button className='btn incorrect'>False</button> */}
          </div>


          <div className={userDidAnswer ? `reveal` : `reveal-hid`}>
            <p>
              True. Literally! As you hear a story, your brain waves synchronise with
              those of the storyteller., according to Princeton Psychology &amp;
              Neuroscience Professor, Uri Hasson*. Meaning storytelling is a great
              approach to presentations.
            </p>
            <p>
              Hobson, Katherine. Clicking: How our brains are in-sync. 11 April
              2018. Avail: <a href="https://paw.princeton.edu/article/clicking-how-our-brains-are-sync" target="_blank">https://paw.princeton.edu/article/clicking-how-our-brains-are-sync</a>
            </p>
          </div>

        </div>
      </div>
    </PageWrapper>
  )
}

export default TrueOrFalse